/* Start:: mail */
.mail-inbox {
    .icons {
        width: 30px;
        height: 30px !important;
        border-radius: 5px;
        background: transparent;
        text-align: center;
        line-height: 30px !important;
        display: block !important;
        padding: 0 !important;
        font-size: 16px;
        color: $gray-7 !important;
        border: 1px solid $default-border;
        margin-inline-end: 8px;
    }
}
.table-inbox tr .inbox-small-cells i {
    color: #e6e9f1;
}
.mail-option {
	.chk-all input[type="checkbox"] {
		margin-block-start: 0;
	}
	.btn-group a.all {
		border: 0 !important;
		padding: 0 !important;
		outline: 0;
		box-shadow: none;
	}
	display: inline-block;
	margin-block-end: 0.625rem;
	width: 100%;
	.chk-all, .btn-group {
		margin-inline-end: 5px;
	}
	.chk-all, .btn-group a.btn {
		border: $default-border;
		border-radius: 3px !important;
		display: inline-block;
		padding: 5px 0.625rem;
	}
    .chk-all, .btn-group a.btn {
        border: 1px solid $default-border;
        border-radius: 3px !important;
        display: inline-block;
        padding: 5px 0.625rem;
        &.show {
            color: $default-text-color;
        }
    }
}
ul.inbox-pagination li {
    float: left;
}
.inbox-pagination{
    li span {
        display: inline-block;
        margin-inline-end: 5px;
        margin-block-start: 7px;
    }
    a.np-btn {
        margin-inline-start: 5px;
        border: 1px solid $default-border;
        border-radius: 3px !important;
        display: inline-block;
        padding: 5px 15px;
    }
} 
/* End:: mail */