/* Start:: cards */

.card {
    margin-block-end: 1.5rem;
    .card-header,.card-footer {
        background-color: $custom-white !important;
        color: $default-text-color;
        border-color: $default-border;
    }
    > {
		hr {
			margin-inline-end: 0;
			margin-inline-start: 0;
		}
		.list-group {
			&:first-child .list-group-item:first-child {
				border-start-start-radius: 0.188rem;
				border-start-end-radius: 0.188rem;
			}
			&:last-child .list-group-item:last-child {
				border-end-end-radius: 0.438rem;
				border-end-start-radius: 0.438rem;
			}
		}
	}
}
[data-theme-mode="dark"] {
    .card {
        &.custom-card {
            box-shadow: 0 0.05rem 0.5rem 0 rgba(14, 15, 46, .1);
        }
    }
}
.card-body {
    color: $default-text-color;
}
.card.custom-card {
    position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	word-wrap: break-word;
	background-clip: border-box;
	position: relative;
    background-color: $custom-white;
	margin-block-end: 1.5rem;
	width: 100%;
	border: 0;
	box-shadow:0 0.15rem 1.75rem 0 rgba(196, 205, 224, .2);
	border-radius: 0.813rem;
    width: 100%;
    .card-header {
        padding: 1.5rem 1.5rem 0.5rem 1.5rem;
        background-color: transparent !important;
        border-block-end: 0px solid $default-border;
        display: flex;
        align-items: center;
        position: relative;
        flex-wrap: wrap;
        gap: 0.25rem;
        &:first-child {
            border-radius: 0.625rem 0.625rem 0 0;
        }
        .card-title:before {
            content: "";
            position: absolute;
            inset-inline-start: -1.5rem;
            padding: 2px;
            height: 1.563rem;
            background: $primary;
        }
        .card-title {
            position: relative;
            margin-block-end: 0;
            font-size: 1.1rem;
            font-weight: 500;
            text-transform: capitalize;
            .subtitle {
                margin-block-end: 0;
                text-transform: initial;
            }
        }
    }
    .card-title {
        position: relative;
        margin-block-end: 0;
        font-size: 1.1rem;
        font-weight: 500;
        margin-block-end: 1.5rem;
    }
    .card-footer {
        background-color: transparent !important;
        border-block-start: 1px solid $default-border;
        padding: 1rem 1.25rem;
    }
    .card-body {
        padding: 1.5rem;
        color: $default-text-color;
    }
    .card-link {
        font-size: 0.75rem;
    }
    .card-text {
        font-size: 0.813rem;
    }
    .footer-card-icon {
        width: 60px;
        height: 60px;
    }
    &.overlay-card {
        position: relative;
        overflow: hidden;
        color: rgba(255,255,255,0.9);
        .card-header {
            border-block-end: 1px solid rgba(255,255,255,0.1);
        }
        .card-footer {
            border-block-start: 1px solid rgba(255,255,255,0.1);
        }
        .over-content-bottom {
            inset-block-start: auto;
        }
        &:before {
            content: "";
            inset-inline-start: 0;
            inset-inline-end: 0;
            inset-block-start: 0;
            inset-block-end: 0;
            background-color: rgba(0,0,0,0.2);
            position: absolute;
        }
    }
    /* Start::Card Background Colors */
    &.card-bg-primary {
            background-color: $primary;
            color: $white;
        .card-header {
            background-color: rgba(255,255,255,0.1) !important;
            color: $white;
            border-block-end: 1px solid rgba(255,255,255,0.2);
            &:before {
                background-color: $white;
            }
        }
        .card-body {
            color: $white;
        }
        .card-footer {
            background-color: rgba(255,255,255,0.1) !important;
            color: $white;
            border-block-start: 1px solid rgba(255,255,255,0.2);
        }
    }
    &.card-bg-secondary {
            background-color: $secondary;
            color: $white;
        .card-header {
            background-color: rgba(255,255,255,0.1) !important;
            color: $white;
            border-block-end: 1px solid rgba(255,255,255,0.2);
            &:before {
                background-color: $white;
            }
        }
        .card-body {
            color: $white;
        }
        .card-footer {
            background-color: rgba(255,255,255,0.1) !important;
            color: $white;
            border-block-start: 1px solid rgba(255,255,255,0.2);
        }
    }
    &.card-bg-warning {
            background-color: $warning;
            color: $white;
        .card-header {
            background-color: rgba(255,255,255,0.1) !important;
            color: $white;
            border-block-end: 1px solid rgba(255,255,255,0.2);
            &:before {
                background-color: $white;
            }
        }
        .card-body {
            color: $white;
        }
        .card-footer {
            background-color: rgba(255,255,255,0.1) !important;
            color: $white;
            border-block-start: 1px solid rgba(255,255,255,0.2);
        }
    }
    &.card-bg-info {
            background-color: $info;
            color: $white;
        .card-header {
            background-color: rgba(255,255,255,0.1) !important;
            color: $white;
            border-block-end: 1px solid rgba(255,255,255,0.2);
            &:before {
                background-color: $white;
            }
        }
        .card-body {
            color: $white;
        }
        .card-footer {
            background-color: rgba(255,255,255,0.1) !important;
            color: $white;
            border-block-start: 1px solid rgba(255,255,255,0.2);
        }
    }
    &.card-bg-success {
            background-color: $success;
            color: $white;
        .card-header {
            background-color: rgba(255,255,255,0.1) !important;
            color: $white;
            border-block-end: 1px solid rgba(255,255,255,0.2);
            &:before {
                background-color: $white;
            }
        }
        .card-body {
            color: $white;
        }
        .card-footer {
            background-color: rgba(255,255,255,0.1) !important;
            color: $white;
            border-block-start: 1px solid rgba(255,255,255,0.2);
        }
    }
    &.card-bg-danger {
            background-color: $danger;
            color: $white;
        .card-header {
            background-color: rgba(255,255,255,0.1) !important;
            color: $white;
            border-block-end: 1px solid rgba(255,255,255,0.2);
            &:before {
                background-color: $white;
            }
        }
        .card-body {
            color: $white;
        }
        .card-footer {
            background-color: rgba(255,255,255,0.1) !important;
            color: $white;
            border-block-start: 1px solid rgba(255,255,255,0.2);
        }
    }
    &.card-bg-light {
            background-color: $light;
            color: $default-text-color;
        .card-header {
            background-color: rgba(0, 0, 0, 0.05) !important;
            color: $default-text-color;
            border-block-end: $default-border;
        }
        .card-body {
            color: $default-text-color;
        }
        .card-footer {
            background-color: rgba(0, 0, 0, 0.05) !important;
            color: $default-text-color;
            border-block-start: $default-border;
        }
    }
    &.card-bg-dark {
            background-color: $dark;
            color: $custom-white;
        .card-header {
            background-color: rgba(255,255,255,0.1) !important;
            color: $white;
            border-block-end: 1px solid rgba(255,255,255,0.2);
            &:before {
                background-color: $custom-white;
            }
        }
        .card-body {
            color: $white;
        }
        .card-footer {
            background-color: rgba(255,255,255,0.1) !important;
            color: $white;
            border-block-start: 1px solid rgba(255,255,255,0.2);
        }
    }
    /* End::Card Background Colors */

    /* Start:: Cards With Links */
    /* Start:: Cards With Links */
    .card-anchor {
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
        inset-block-end: 0;
        inset-inline-start: 0;
        z-index: 1;
        pointer-events: auto;
        content: "";
    }
    /* End:: Cards With Links */

    /* Start:: Card With Collapse */
    .collapse-close {
        display: none;
    }
    &.card .collapsed {
        .collapse-close {
            display: block;
        }
        .collapse-open {
            display: none;
        }
    }
    /* End:: Card With Links */

    /* Start::Card With FullScreen */
    &.card-fullscreen {
        position: fixed;
        inset-block-start: 0;
        inset-inline-start: 0;
        inset-inline-start: 0;
        inset-block-end: 0;
        z-index: 9999;
        margin: 0;
        border-radius: 0;
    }
    /* End::Card With FullScreen */
}
.card-title {
    color: $default-text-color;
}
.card {
    color: $default-text-color;
    background-color: $custom-white;
}
.card-table tr td:first-child, .card-table tr th:first-child {
    padding-inline-start: 1.5rem;
}
.card-table-one {
    .table tr:last-child td  {
        border-bottom-width: 0;
    }
}
@media (max-width: 576px) {
    .card-text2 {
        display: none;
    }
}
/* End:: cards */