/* Start:: height */

.ht-5 {
	height: 5% !important;
}
.h-10 {
	height: 10% !important;
}
.h-15 {
	height: 15% !important;
}
.h-20 {
	height: 20% !important;
}
.h-25 {
	height: 25% !important;
}
.h-30 {
	height: 30% !important;
}
.h-35 {
	height: 35% !important;
}
.h-40 {
	height: 40% !important;
}
.h-45 {
	height: 45% !important;
}
.h-50 {
	height: 50% !important;
}
.h-55 {
	height: 55% !important;
}
.h-60 {
	height: 60% !important;
}
.h-65 {
	height: 65% !important;
}
.h-70 {
	height: 70% !important;
}
.h-75 {
	height: 75% !important;
}
.h-80 {
	height: 80% !important;
}
.h-85 {
	height: 85% !important;
}
.h-90 {
	height: 90% !important;
}
.h-95 {
	height: 95% !important;
}
.h-100vh {
	height: 100vh !important;
}
.h-100 {
	height: 100% !important;
}
.h-auto {
	height: auto !important;
}
.h-0 {
	height: 0 !important;
}
.h-1 {
	height: 0.25rem !important;
}
.h-2 {
	height: 0.5rem !important;
}
.h-3 {
	height: 0.75rem !important;
}
.h-4 {
	height: 1rem !important;
}
.h-5 {
	height: 1.5rem !important;
}
.h-6 {
	height: 2rem !important;
}
.h-7 {
	height: 3rem !important;
}
.h-8 {
	height: 4rem !important;
}
.h-9 {
	height: 6rem !important;
}
.h-100h {
	height: 100px !important;
}
.h-150 {
	height: 150px !important;
}
.h-200 {
	height: 200px !important;
}
.h-250 {
	height: 250px !important;
}
.h-300 {
	height: 300px !important;
}
.h-350 {
	height: 350px !important;
}
.h-370 {
	height: 370px !important;
}
.h-400 {
	height: 400px !important;
}
.h-420 {
	height: 420px !important;
}
.h-450 {
	height: 450px !important;
}
.h-136{
	height: 136px !important;	
}
.h-500 {
	height: 500px !important;
}

.mh-100 {
	max-height: 100% !important;
}
.h-85 {
	height: 85px !important;
}
/* End:: height */