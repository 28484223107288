/* Start:: menu_hover */
[data-nav-style="menu-hover"][data-nav-layout="horizontal"] {
    @extend .menu-hover;
    @media (min-width: 992px) {
        .app-sidebar {
            .side-menu__item {
                display: flex !important;
                padding-block-start: 0.93rem;
                padding-inline-end: 1.75rem;
                padding-block-end: 0.93rem;
                padding-inline-start: 0.75rem;
            }
            .side-menu__icon {
                margin-block-end: 0 !important;
                margin-inline-end: 0.35rem !important;
            }
            .side-menu__angle {
                display: block !important;
            }
            .slide.has-sub .slide-menu {
                &.active {
                    inset-inline-start: auto !important;
                    inset-inline-end: auto !important;
                }
                &.child1 {
                    inset-block-start: 100% !important;
                }
            }
        }
        .app-sidebar {
            .side-menu__item {
                &.active, &:hover {
                    .side-menu__angle, .side-menu__label, .side-menu__icon {
                        color: $primary;
                    }
                }
            }
        }
    }
}
[data-nav-style="menu-hover"][data-toggled="menu-hover-closed"] {
    @extend .menu-hover;
}
[data-nav-style="menu-hover"][data-nav-layout="vertical"][data-toggled="menu-hover-closed"] {
    @media (min-width: 992px) {
        .app-sidebar {
            position: absolute;
            .side-menu__item {
                border-radius: 0.5rem;
                margin: 0.25rem 0.25rem 0 0.25rem !important;
                &:last-child {
                    margin: 0.25rem !important;
                }
            }
        }
        .app-sidebar {
            .slide .slide-menu {
                &.child1,&.child2,&.child3 {
                    border-radius: 0 0.5rem 0.5rem 0;
                }
            } 
        }
        &[dir="rtl"] {
            .app-sidebar {
                .slide .slide-menu {
                    &.child1,&.child2,&.child3 {
                        border-radius: 0.5rem 0 0 0.5rem;
                    }
                } 
            }
        }
        
        .user-info ,.Annoucement_card {
            display: none;
        }
        
        .app-sidebar__user {
            padding-block: 0.75rem;
            padding-inline: 0;
            margin-block-end: 0;
        }
        .user-pic {
            .avatar-xxl {
                width: 4rem;
                height: 4rem;
            }
        }
    }
}
.menu-hover {
    @media (min-width: 992px) {
        .app-sidebar {
            width: 9.5rem;
            .main-sidebar {
                overflow: visible;
                height: 90%;
            }
            .main-sidebar-header {
                width: 9.5rem;
            }
            .side-menu__icon {
                margin-inline-end: 0;
                margin-block-end: 0.5rem;
            }
            .slide {
                padding: 0;
            }
            .slide-menu {
                &.child1,
                &.child2,
                &.child3 {
                    min-width: 12rem;
                    display: none !important;
                    .slide {
                        .side-menu__item {
                            text-align: start;
                            &:before {
                                inset-block-start: 0.938rem;
                                inset-inline-start: 0.75rem;
                            }
                        }
                    }
                    .slide.has-sub,.slide {
                        &.side-menu__label1 {
                            display: none;
                        }
                    }
                }
            }
            .slide.has-sub:hover {
                .slide-menu.child1 {
                    display: block !important;
                    .slide.has-sub:hover {
                        .slide-menu.child2 {
                            display: block !important;
                            .slide.has-sub:hover {
                                .slide-menu.child3 {
                                    display: block !important;
                                }
                            }
                        }
                    }
                }
            }
            .slide__category,
            .side-menu__angle {
                display: none;
            }
            .slide-menu {
                &.child1,
                &.child2,
                &.child3 {
                    .side-menu__angle {
                        display: block;
                        inset-inline-end: 1rem;
                        inset-block-start: 0.65rem;
                    }
                }
            }
            .side-menu__item,
            .side-menu__label {
                display: block;
                text-align: center;
            }
            .slide.has-sub .slide-menu {
                position: absolute !important;
                inset-inline-start: 9.5rem !important;
                background: var(--menu-bg);
                inset-block-start: auto !important;
                box-shadow: 0.125rem 0.063rem 0.5rem $black-1;
                transition: none !important;
                &.child2,
                &.child3 {
                    inset-inline-start: 12rem !important;
                }
            }
            .simplebar-content-wrapper {
                position: initial;
            }
            .simplebar-mask {
                position: inherit;
            }
            .simplebar-placeholder {
                height: auto !important;
            }
        }
        .app-header {
            padding-inline-start: 9.5rem;
        }
        .app-content {
            margin-inline-start: 9.5rem;
        }
    }
}
/* End:: menu_hover */