/* Start:: ecommerce */
.checkout-payment-success {
    text-align: center;
}

.saved-cards {
    width: 4.6rem;
    height: 2.75rem;
    padding: 6px 13px;
    display: flex;
    box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.08);
    align-items: center;
    justify-content: center;
    background: $custom-white;
    border: 1px solid var(--default-border);
    border-radius: 0.25rem;
}

.product-checkout {
    .tab-style-2 .nav-item .nav-link {
        padding: 0.85rem 2rem;
    }

    .tab-style-2 .nav-item .nav-link.active:before {
        content: none;
    }

    .tab-style-2 .nav-item .nav-link.active {
        font-weight: 500;
    }
}


.image-check {
    width: 6rem;
    height: 6rem;
    background: linear-gradient(to bottom right, $success, rgba(var(--success-rgb), 0.8));
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
        0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    transform: scale(0.84);
    border-radius: 50%;
    animation: checkanimation 0.75s ease-out forwards 0.75s;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;

    svg {
        width: 65%;
        transform: translateY(0.25rem);
        stroke-dasharray: 80;
        stroke-dashoffset: 80;
        animation: svganimation 0.35s forwards 1.25s ease-out;
    }
}

@keyframes checkanimation {
    0% {
        opacity: 0;
        transform: scale(0);
        box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    }

    25% {
        opacity: 1;
        transform: scale(0.9);
        box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    }

    43.75% {
        transform: scale(1.15);
        box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
    }

    62.5% {
        transform: scale(1);
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
    }

    81.25% {
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
    }

    100% {
        opacity: 1;
        box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
            0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
    }
}

@keyframes svganimation {
    from {
        stroke-dashoffset: 80;
    }

    to {
        stroke-dashoffset: 0;
    }
}

.product-icons {
    display: none;
}

.product-card {
    &:hover {
        .product-icons {
            display: block;

            .wishlist {
                background-color: rgba(var(--danger-rgb), .2);
                color: rgb(var(--danger-rgb));
                inset-block-start: 1.75rem;
                inset-inline-end: 1.75rem;
                position: absolute;
                width: 1.75rem;
                height: 1.75rem;
                border-radius: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

[dir="rtl"] {
    .item-card .product-links li {
        transform: translateX(-60px);
    }
}

@media screen and (max-width: 575px) {
    #shipped-tab-pane .btn-group .btn {
        width: 100%;
        border-radius: 0.5rem;
    }
}

.saved-cards {
    img {
        border-radius: .25rem;
    }
}

.product-checkout .payment-card-container,
.product-checkout .shipping-method-container {
    position: relative;
    padding: 0.625rem;
    border: 1px solid var(--input-border);
    border-radius: 0.5rem;
}

.product-checkout .payment-card-container .form-check-input,
.product-checkout .shipping-method-container .form-check-input {
    position: absolute;
    inset-inline-end: 0.75rem;
    inset-block-start: 1.2rem;
}

@media (min-width: 576px) {

    .product-checkout .payment-card-container .saved-card-details,
    .product-checkout .payment-card-container .shipping-partner-details,
    .product-checkout .shipping-method-container .saved-card-details,
    .product-checkout .shipping-method-container .shipping-partner-details {
        width: 12.5rem;
    }
}

/* End:: ecommerce */