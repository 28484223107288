/* Start:: header */

/* Start::app-header */
.app-header {
  max-width: 100%;
  padding: 1.2rem 0;
  z-index: 100;
  position: fixed;
  inset-block-start: 0;
  inset-inline: 0;
  background: $header-bg;
  border-block-end: 1px solid $header-border-color;
  transition: all .1s ease;
  box-shadow: 0px 6px 18px rgba(196, 205, 224, 0.1);

  #mainHeaderProfile {
    color: $header-prime-color;
  }
  .main-header-container {
    .main-header-profile .header-link {
      background: none;
    }
  }
}

@media (min-width: 992px) {
  .app-header {
    padding-inline-start: 16.875rem;
  }
}

.header-icon-badge {
  position: absolute;
  padding: 0.2rem 0.3rem;
  inset-block-start: 0.3rem;
  inset-inline-end: 0.3rem;
  font-size: .6rem;
}

.related-app {
  text-align: center;
  border-radius: $default-radius;
  border: 1px solid $default-border;

  &:hover {
    background-color: $default-background;
  }
}

.header-profile-dropdown {
  min-width: 11rem;
}

/* End::app-header */

/* Start::main-header-container */
.main-header-container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;

  .header-content-left,
  .header-content-right {
    display: flex;
    align-items: stretch;
  }

  .header-element {
    display: flex;
    align-items: center;
  }

  .header-link {
    display: flex;
    align-items: center;
    padding: 1rem 0.65rem;
    margin: 0 4px;
    padding: 0.5rem;
    text-align: center;
    font-size: 1.2rem;
    position: relative;
    border-radius: 0.5rem;
    line-height: 2;
    background: $light2;
    justify-content: center;
    height: 2.813rem;
    width: 2.813rem;

    &:hover,
    &.show {
      .header-link-icon {
        fill: $header-prime-color;
        color: $header-prime-color;
      }
    }
  }

  .header-link-icon {
    font-size: 1.25rem;
    color: $header-prime-color;
    fill: $header-prime-color;
    transition: all ease 0.05s;
  }

  .dropdown-toggle {
    text-decoration: none;

    &::after {
      content: none;
    }
  }

  .main-profile-user {
    .dropdown-menu {
      width: 13rem;
    }

    .dropdown-item {
      font-weight: 400;
      font-size: 0.813rem;
      color: $default-text-color;
      padding: 1.25rem 1rem;
      height: 2.375rem;
      display: flex;
      align-items: center;

      &:hover {
        color: $primary;

        i {
          color: $primary;
          opacity: 1;
        }
      }
    }
  }

  .main-header-dropdown {
    box-shadow: 0px 16px 18px rgba(6, 10, 48, 0.1);
    inset-block-start: 12px !important;
    &.header-shortcuts-dropdown {
      width: 22rem;
    }
    .dropdown-item {
      padding: 0.65rem;
      border-block-end: 1px solid $default-border;
      &:last-child {
        border-block-end: 0;
      }
    }
  }

  .cart-dropdown .main-header-dropdown {
    width: 22rem;
  }

  .notifications-dropdown .main-header-dropdown {
    width: 22rem;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(6rem);
  }

  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
    transform: translateY(6rem);
  }
}

[dir="rtl"] {
  .sidemenu-toggle {
    .open-toggle {
      transform: rotate(180deg);
    }
  }
}

/* End::main-header-container */

/* Start::Header-dropdown */
.header-product-item {
  padding-inline-start: 0;
  display: flex !important;
  flex-direction: column;

  li {
    list-style-type: none;
    font-size: 0.75rem;
    font-weight: 400;
  }

  li:nth-child(2),
  li:nth-child(3),
  li:nth-child(4) {
    &:before {
      position: absolute;
      content: "";
      inset-inline-start: -0.438rem;
      width: 1px;
      inset-block-start: 0.25rem;
      height: 0.625rem;
      background-color: $text-muted;
      opacity: 0.1;
    }
  }
}

.header-cart-remove {

  .bi {
    padding: 0.25rem;
    border-radius: 0.25rem;
    font-size: 0.85rem;
    margin-inline-start: 0.75rem;
    color: $danger;
  }

  &:hover {
    .bi {
      background-color: rgba(var(--danger-rgb), 0.1);
    }
  }
}

/* End::Header-dropdown */

/* Start::header-search */
#searchModal {
  .form-control {
    position: relative;
  }

  .input-group {
    border: 2px solid $primary;
    border-radius: 0.25rem;

    i {
      color: $text-muted;
    }
  }
}

.search-tags {
  font-size: 0.75rem;
  color: $default-text-color;
  border: 1px solid $default-border;
  border-radius: 0.25rem;
  background-color: $light;
  padding: 0.125rem 0.55rem;
  line-height: 1.2rem;
  display: inline-flex;
  align-items: center;
  cursor: default;
  font-weight: 400;
  margin: 0.25rem 0;

  .tag-addon:last-child {
    border-radius: $default-radius;
  }

  a.tag-addon {
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    padding: 0 0.5rem;
    color: inherit;
    text-decoration: none;
    background: $light;
    margin-inline-end: -0.4rem;
    margin-inline-start: 0.5rem;
    text-align: center;
    min-width: 1.5rem;

    i {
      vertical-align: middle;
      margin: 0 -0.25rem;
      line-height: 1.5px;
      font-size: 0.813rem;
    }
  }

  i {
    font-size: 0.813rem;
  }
}

/* End::header-search */

/* Start::header-country-selector */
.country-selector {
  .header-link img {
    width: 1.25rem;
    height: 1.25rem;
  }

  .dropdown-menu {
    img {
      width: 1rem;
      height: 1rem;
    }
  }
}

/* End::header-country-selector */

/* Start:header dropdowns scroll */
#header-shortcut-scroll,
#header-cart-items-scroll {
  max-height: 20rem;
}

/* End:header dropdowns scroll */

/* Start::header badge pulse */
.pulse {
  display: block;
  cursor: pointer;
  animation: pulse-secondary 2s infinite;

  &.pulse-secondary {
    box-shadow: 0 0 0 rgba(var(--secondary-rgb), 0.4);
  }
}

@-webkit-keyframes pulse-secondary {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 0.625rem rgba(var(--secondary-rgb), 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0);
  }
}

@keyframes pulse-secondary {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0.4);
    box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 0.625rem rgba(var(--secondary-rgb), 0);
    box-shadow: 0 0 0 0.625rem rgba(var(--secondary-rgb), 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0);
    box-shadow: 0 0 0 0 rgba(var(--secondary-rgb), 0);
  }
}

/* End::header badge pulse */

/* Start::Header theme-mode icon style */
[data-theme-mode="light"] {
  .layout-setting .dark-layout {
    display: none;
  }

  .layout-setting .light-layout {
    display: block;
  }
}

.layout-setting .dark-layout {
  display: none;
}

.layout-setting .light-layout {
  display: block;
}

[data-theme-mode="dark"] {
  .layout-setting .light-layout {
    display: none;
  }

  .layout-setting .dark-layout {
    display: block;
  }
}

/* End::Header theme-mode icon style */

/* Start::Header responsive */
@media (min-width: 991.98px) and (max-width: 1200px) {
  .app-header .header-content-left .form-inline .form-control {
      width: 10rem;
      min-width: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .app-header .dropdown-menu {
      width: 100%;
  }
}
@media (max-width: 991.98px) {
  .main-header-dropdown.dropdown-menu-end,
  .main-header-dropdown.dropdown-menu-start{
    &:after, &::before {
      content: none;
    }
  }
}
@media (max-width: 330px) {
  .header-content-right {
    .header-theme-mode {
      display: none;
    }
  }
}
/* End::Header responsive */

/* Start::Header fullscreen responsive */
@media (max-width:767.98px) {
  .header-element.header-fullscreen {
    display: none;
  }
}

/* End::Header fullscreen responsive */

/* Start::Responsive header dropdowns */
@media (max-width:575.98px) {
  .app-header {
    .dropdown-menu {
      width: 100%;
    }
  }
  .animated-arrow.hor-toggle {
    margin-inline-end: 0;
  }
}

/* End::Responsive header dropdowns */

/* Start::toggle */
.animated-arrow.hor-toggle {
  text-align: center;
  font-size: 1.2rem;
  position: relative;
  margin-inline-end: 1.3rem;
}

.sidemenu-toggle {
  margin: 0 6px;
  padding: 0.5rem;
  text-align: center;
  position: relative;
  border-radius: 0.5rem;
  line-height: 2;
  background: var(--default-body-bg-color);
  justify-content: center;
  height: 2.813rem;
  width: 2.813rem;
  margin-inline-end: 1.3rem;
}

.animated-arrow {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  z-index: 102;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  transition: all 0.05s ease-in-out;
  cursor: pointer;
  padding: .3125rem;
  margin-block-start: .375rem;
  margin-block-end: 0;
  margin-inline-start: 0.5rem;
  margin-inline-end: 0;
}

.animated-arrow.hor-toggle span {
  vertical-align: middle;
}

.animated-arrow span {
  cursor: pointer;
  height: .125rem;
  width: 1.0625rem;
  background: $header-prime-color;
  position: absolute;
  display: block;
  content: "";
  transition: all 0.05s ease-in-out;
}

.animated-arrow span:before,
.animated-arrow span:after {
  transition: all 0.05s ease-in-out;
}

.animated-arrow span:before {
  inset-block-start: -0.375rem;
  width: 1.4375rem;
}

.animated-arrow span:after {
  inset-block-end: -0.375rem;
  width: 15px;
}

.animated-arrow span:before,
.animated-arrow span:after {
  cursor: pointer;
  height: .125rem;
  width: 1.0625rem;
  background: $header-prime-color;
  position: absolute;
  display: block;
  content: "";
}

[data-toggled="icon-overlay-close"],[data-toggled="close-menu-close"],[data-toggled="icon-text-close"],[data-toggled="detached-close"],[data-toggled="menu-click-closed"],[data-toggled="menu-hover-closed"],[data-toggled="icon-click-closed"],[data-toggled="icon-hover-closed"] {
  .animated-arrow span {
    background-color: transparent;
    background: none !important;
  }

  .animated-arrow span:before {
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    inset-block-end: 0px;
  }

  .animated-arrow span:before {
    inset-block-start: -1px;
  }

  .animated-arrow span:after {
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .animated-arrow span:after {
    width: 1.0625rem;
    inset-block-start: -1px;
  }
}

/* End::toggle */

/* Start::header notification dropdown */
.header-notification-text {
  max-width: 14.5rem;
}
/* Start::header notification dropdown */

/* Start:Page header styles */
.page-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin: 2.3rem 0 2rem;
	justify-content: space-between;
	padding: 0;
	border-radius: 7px;
	position: relative;
	min-height: 50px;
	.breadcrumb-icon {
		margin-block-start: 3px;
		margin-inline-start: 4px;
	}
	.breadcrumb-item {
		.svg-icon {
			width: 1.25rem;
		}
		&:first-child {
			margin-block-start: 0;
		}
		margin-block-start: 3px;
	}
}
.page-leftheader {
    margin-block-end: .25rem;
    margin-block-start: .25rem;
}
.page-title {
	margin: 0;
	font-size: 1.5625rem;
	font-weight: 500;
	line-height: 2.5rem;
	position: relative;
  span {
    display: inline-block;
  }
}
@media (max-width: 375px) {
  .page-title {
      font-size: 1.25rem !important;
  }
}
.page-rightheader .btn i {
  font-size: 1rem;
  line-height: 1.35;
}
@media (min-width: 768px) {
	.page-header .page-rightheader{
		margin-block-start: 0px;
	}
}
@media (max-width: 767.98px) {
	.page-header {
    margin: 1.5rem 0 2rem;
  }
}
/* End:Page header styles */

/* Start:Header search */
.search-element {
	position: relative;
}
.app-header .form-inline {
	.form-control {
		border: 0;
		padding-inline-start: 20px;
		width: 23rem;
		padding-inline-end: 2.5rem;
		padding-block-start: 0.438rem;
		background: $light2;
		margin-inline-start: 0;
		height: 2.813rem;
		&:focus {
			box-shadow: none;
		}
	}

  input[type="search" i]::-webkit-search-cancel-button {
    display: none !important;
  }

	.btn {
		border-radius: 0;
    padding-block-start: 0.313rem;
    padding-block-end: 0.375rem;
    padding-inline: 0.938rem;
		border: 2px solid transparent;
		box-shadow: none;
		border-end-end-radius: 0.188rem;
		border-start-end-radius: 0.188rem;
		background: transparent;
		color: $custom-white;
		margin-inline-start: -3.5rem;
		font-size: 0.938rem;
		position: absolute;
		inset-inline-end: 0;
		inset-block-start:0.188rem;
		i {
			color: $default-text-color;
      line-height: 1.6;
		}
	}
}
/* End:Header search */

/* Start:header notification-bar */
#notification-sidebar-canvas {
  width: 21.875rem !important;
  box-shadow: 5px 7px 26px -5px #d5d4e0;
}
[data-theme-mode="dark"] {
  #notification-sidebar-canvas {
    box-shadow: 0 0.05rem 0.5rem 0 rgba(14, 15, 46, 0.1);
  }
}
/* End:header notification-bar */

/* End:: header */