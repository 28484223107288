/* Start:: authentication */
.login-bg, .message-bg {
    background: url(../images/media/44.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.login-bg, 
.message-bg, 
.login-bg1 {
    &::before {
        background: var(--primary03);
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.login-bg1, .message-bg, .error-bg, .error-bg1, .login-bg {
    @media (min-width: 992px) {
        .container {
            max-width: 85% !important;
        }
    }
}
/* coming */
.error-1 {
    .coming-footer {
        inset-inline-start: 0;
        inset-inline-end: 0;
        margin: 0 auto;
        padding-block-start: 1.5rem;
    }
}
/* coming */
/* construction */
.construction-bg{
	background: url('../images/media/png/2.png');
	background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
	&::before{
		content: '';
		position: absolute;
		width:100%;
		height: 100%;
		background:$primary-09;
	}
}
.construction {
	.construction-search {
		background: transparent;
    	border-radius: 3.125rem;
        .form-control{
            border: 1px solid rgba(255,255,255, .1);
            color: $white;
            height: 2.5rem;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 3.125rem;
            &:focus {
                border:1px solid rgba(255,255,255, .1);
                box-shadow: none !important;
                color: $white;
                background: transparent;
            }
            &::-webkit-search-cancel-button {
                margin-inline-end: 40px;
                filter: invert(1);
            }
        }
	}
	
	.construction-search {
        .btn {
            position: absolute;
            inset-inline-end: 0.313rem;
            inset-block-start: 0.188rem;
            color: rgba(255,255,255, .3);
        }
        .btn-check:active+.btn:focus, .btn-check:checked+.btn:focus, .btn.active:focus, .btn.show:focus, .btn:active:focus {
            border-color: transparent;
        }
    }
}
@media (max-width: 480px) {
    .construction .display-5 {
        font-size: 1.5rem;
    }
}
.under-maintenance-time {
    display: inline-block;
    font-size: 1.83rem;
    width: 105px;
    height: 105px;
    border-radius: 0px;
    line-height: 28px;
    padding: 20px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    z-index: 1;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
    color: #fff;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
}
/* construction */
/* lock screen */
.error-page3 {
    .log-image {
        height: 100vh !important;
    }
    .cover-image {
        min-height: 100%;
    }
}
.customlogin-imgcontent {
    position: absolute;
    inset-block-start: 35%;
    padding: 5rem;
}

@media (min-width: 992px) {
    .p-lg-9 {
        padding: 6rem !important;
    }
}
.cover-image {
    background-size: cover !important;
}
.lockscreen3 {
    background: url(../images/media/46.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.login-bg1 {
    background: url(../images/media/45.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
/* lock screen */
/* reset password 3 */
.reset-password-3 {
    background: url(../images/media/46.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.log-image1 {
    min-height: 100vh !important;
}
.no-gutters {
	margin-inline-end: 0;
	margin-inline-start: 0;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 0;
			padding-inline-start: 0;
		}
	}
}
/* reset password 3 */
/* forgot password 3 */
.forgot-password3 {
    background: url(../images/media/46.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.login-bg1 .custom-logo-dark, 
.customlogin-content .custom-logo-dark {
    display: none;
}
/* forgot password 3 */
/* register 3 */
.register3 {
    background: url(../images/media/46.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
/* register 3 */
/* login 3 */
.login3 {
    background: url(../images/media/46.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
/* login 3 */
[data-theme-mode="dark"] {
    .customlogin-content, .login-bg1 {
        .custom-logo-dark {
            display: block;
        }
        .custom-logo {
            display: none;
        }
    }
}

/* End:: authentication */