/* Start:: landing */

@media (min-width: 992px) {
  [data-nav-style="menu-hover"][data-nav-layout="horizontal"] {
    &[data-theme-mode="dark"] {
      .landing-body
        .app-sidebar
        .slide.has-sub.open
        .slide-menu.child1::before {
        border-block-start-color: rgba(0, 0, 0, 0.1);
        border-inline-start-color: rgba(0, 0, 0, 0.1);
      }
      &[dir="rtl"] {
        .slide.has-sub.open .slide-menu.child1 {
          &::before {
            border-inline-end-color: var(--border);
            border-inline-start-color: transparent !important;
          }
        }
      }
    }
    .landing-body {
      .app-sidebar {
        .side-menu__item .side-menu__angle {
          color: $default-text-color;
        }
        .slide.has-sub.open {
          .slide-menu {
            &.child1,
            &.child2,
            &.child3 {
              .slide .side-menu__item {
                .side-menu__angle {
                  color: $menu-prime-color !important;
                }
                &:hover,
                &.active {
                  color: $primary;
                  .side-menu__angle {
                    color: $primary !important;
                  }
                  &:before {
                    border-color: $primary !important;
                  }
                }
              }
            }
          }
        }

        .side-menu__item {
          border-radius: 0.25rem;
          padding: 1.5rem;
        }

        .slide-menu.child1 {
          border-radius: 0.25rem;
          padding: 0.55rem 0.3rem;
        }

        .slide-menu.child1 .side-menu__item {
          padding: 0.33rem 1.6rem;
        }
        .side-menu__item {
          padding-inline-end: .95rem;
          padding-inline-start: .95rem;
        }

        .side-menu__item.active,
        .side-menu__item:hover {
          background-color: transparent !important;
        }

        .slide-menu.child1 .slide .side-menu__item:before {
          inset-block-start: 0.838rem;
          inset-inline-start: 0.65rem;
        }
        .slide-menu.child1.mega-menu .slide .side-menu__item:before {
          inset-block-start: 0.95rem;
          inset-inline-start: 0.8rem;
        }

        .side-menu__item:hover .side-menu__angle {
          color: $primary !important;
        }
      }
      .app-sidebar {
        .side-menu__label {
          color: $default-text-color;
        }
        .side-menu__item {
          &.active,
          &:hover {
            background-color: $primary;
            color: $primary;

            .side-menu__label,
            .side-menu__angle {
              color: $primary !important;
            }
          }
        }
      }

      .app-sidebar .slide-menu.mega-menu {
          &.child1 {
              .slide .side-menu__item {
                padding: 0.5rem 1.6rem;
              }
          }
      }
       .app-sidebar .slide.has-sub .mega-menu.slide-menu{
        width: 100%;
       }
      .swiper-button-next, .swiper-button-prev {
        border-radius: 50%;
        width: 2.5rem !important;
        height: 2.5rem !important;
        text-align: center;
        background: rgba(255, 255, 255, 0.5) !important;
        box-shadow: 0 4px 15px rgba(67, 67, 67, 0.1);
        border: 1px solid $default-border !important;
        &:after {
          font-size: 0.5rem !important;
          color: $primary !important;
        }
      }
      .main-menu {
          align-items: center;
      }
      .app-sidebar {
        .slide.has-sub {
          .mega-menu {
            &.slide-menu {
              inset-inline-start: 0 !important;
              columns: 1 !important;
            }
          }
        }
      }
      .slide.has-sub.open {
        .mega-menu1 {
          position: relative;
          inset-inline-start: 0;
          columns: 1;
        }
      }
      .mega-subtitle {
        font-size: 0.875rem;
        font-weight: 500;
        position: relative;
        text-transform: uppercase;
        opacity: 0.8;
        margin-block-end: 1.3rem;
        &.without-title {
            margin-block-end: 2.5rem !important;
            &::before {
              border-color: transparent;
            }
        }
      }
      .app-sidebar .slide-menu.child1.mega-menu {
        padding: 0.8rem 0.75rem;
      }
    }
  }
  .flex-none {
    flex: none;
  }
}
@media (min-width: 1200px) {
  [data-nav-layout="horizontal"] {
    .landing-body {
      .container-fluid.main-app-content, .main-container  {
        width: 83%;
        margin: 0 auto;
      }
      .main-sidebar {
        width: 83%;
      }
      .main-banner-container, .main-banner-container {
        width: 83%;
        margin: 0 auto;
      }
    }
  }
}
@media (max-width: 1200px) {
  .main-content .main-container, .main-banner-container  {
      max-width: 92% !important;
      margin: 0 auto;  
  }
}
@media (min-width: 991.98px) and (max-width: 1199.98px){
  [data-nav-layout="horizontal"] {
    .landing-body {
      .container-fluid.main-app-content {
        width: 94%;
        margin: 0 auto;
      }
      .main-banner-container {
        width: 94%;
        margin: 0 auto;
      }
    }
  }
}

.landing-body {
  background-color: $custom-white;

  .app-sidebar {
    border-block-end: 0;
    .side-menu__item {
      padding: 0.8rem 1rem;

      &.active,
      &:hover {
        color: $primary !important;
      }
    }
  }
  .swiper-button-prev, .swiper-button-next {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .app-sidebar.sticky.sticky-pin {
    .side-menu__item.active {
      font-weight: normal;

      .side-menu__label {
        color: $primary !important;
      }
      .side-menu__angle {
        color: $primary !important;
      }
    }
  }
  @media (min-width: 991.98px) {
    .app-sidebar.sticky {
      .side-menu__item.active::after {
        position: absolute;
        inset-block-end: 0.75rem;
        inset-inline-start: 19%;
        content: "";
        color: transparent;
        text-shadow: transparent 0px 0px;
        font-size: 1.3em;
        pointer-events: none;
        height: 0.125rem;
        width: 2.75rem;
        transition: all 250ms ease-in-out 0s;
        background: $primary;
      }
      .child1 {
        .side-menu__item.active::after {
          display: none;
        }
      }
    }
  }

  .accordion.accordion-primary .accordion-button.collapsed:after {
    background-color: $primary-01;
    color: $primary;
  }

  .featur-icon {
    width: 75px;
    height: 75px;
    padding: 14px;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.05);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
  }

  .feature-logos {
    display: flex;
    justify-content: center;
  }

  .landing-Features {
    position: relative;
    width: 100%;
    height: 100%;
    inset-block-start: 0;
    background-image: url("../images/media/landing/banner2.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 9;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(var(--primary-rgb), 0.9);
      inset-block-start: 0px;
      z-index: -1;
    }
  }

  .header-element, .main-menu-container {
    .btn-list .btn {
      margin-block-end: 0;
    }
  }

  @media (min-width: 992px) {
    .app-sidebar {
      inset-block-start: 0;
      padding: 0.5rem 0;
      height: auto !important;
      background-color: $custom-white;
      box-shadow: none;
      border-inline-end: 0;

      .main-sidebar {
        height: auto !important;
      }

      &.sticky.sticky-pin {
        background-color: $custom-white;
        box-shadow: 0 0.25rem 1rem $black-1;

        .side-menu__item {
          .side-menu__angle {
            color: $menu-prime-color;
          }
          &:hover {
            .side-menu__angle {
              color: $primary !important;
            }
          }
        }
        &.app-sidebar .side-menu__label {
          color: $custom-black;
        }

        .landing-logo-container .horizontal-logo {
          .desktop-white {
            display: none;
          }

          .desktop-logo {
            display: block;
          }
        }
        &.app-sidebar .side-menu__item:hover {
          .side-menu__label {
            color: $primary !important;
          }
        }
      }
      &.app-sidebar .slide.has-sub.open .slide-menu.child1::before {
        border-block-start-color: $default-border !important;
        border-inline-start-color: $default-border !important;
      }
    }

    .app-header {
      display: none;
    }

    .main-sidebar-header {
      display: block !important;
    }

    .main-menu-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .slide-left,
      .slide-right {
        display: none;
      }
    }

    .main-content {
      padding: 0;
    }

    .landing-logo-container {
      .horizontal-logo .header-logo {
        .desktop-logo {
          display: block;
        }
        .desktop-white {
          display: none;
        }
      }
    }
  }

  @media (max-width: 991.98px) {
    .app-sidebar .side-menu__item.active,
    .app-sidebar .side-menu__item:hover {
      background-color: transparent;
    }
    .main-menu-container .main-menu {
      padding-inline: 20px !important;
      width: 100%;
    }
    .app-sidebar .slide-menu {
      padding-inline-start: 1rem;
    }

    .app-sidebar {
      .slide {
        padding: 0;
      }
    }

    .landing-logo-container {
      .horizontal-logo .header-logo {
        .desktop-logo {
          display: none !important;
        }

        .desktop-white {
          display: none !important;
        }
      }
    }

    .main-content {
        padding-block-start: 5.2rem;
    }
  }

  .main-menu-container .main-menu {
    padding-inline-start: 0;
  }

  .main-header-container .header-link-icon {
    color: #fff;
    fill: #fff;
    font-size: 0.95rem;
  }

  .app-content {
    margin-block-start: 4rem;
  }

  .section {
    padding: 3.375rem 0;
    background-size: cover;
    position: relative;
  }

  .landing-banner {
    position: relative;
    width: 100%;
    inset-block-start: 0;
    background-image: url("../images/media/landing/banner.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $primary-09;
    }

    .landing-banner-heading {
      line-height: 1.25;
      text-shadow: 1px 1px $black-1;
      font-size: 3rem;
      font-weight: 600;
      color: $white;
    }
    
  &.landing-banner2 {
    background-image: url("../images/media/landing/banner1.jpg") !important;

  }

  }
  @media (max-width: 767.98px) {
    .landing-main-image {
      display: none;
    }

    .landing-banner {

      .main-banner-container {
        padding: 1rem;
      }
    }
  }

  @media (max-width: 1115.98px) {
    .landing-main-image {
      &::before,
      &::after {
        display: none;
      }
    }
  }

  @media (max-width: 480px) {
    .landing-banner {
      .section {
        padding: 2.375rem 0;
      }
    }
  }

  .landing-main-image {
    position: relative;
    z-index: 10;

    img {
      z-index: 11;
      position: relative;
      width: 27.063rem;
      height: 26.063rem;
      margin-block-start: -0.625rem;
    }

    &:before {
      content: "";
      position: absolute;
      width: 24.5rem;
      height: 24.5rem;
      background-color: $black-1;
      opacity: 0.3;
      inset-inline-start: 5rem;
      inset-block-start: 0rem;
      border-radius: 50%;
      box-shadow: 0 3rem 10rem $white-1 !important;
    }

    &:after {
      content: "";
      position: absolute;
      width: 12.5rem;
      height: 12.5rem;
      background-color: transparent;
      border: 0.125rem dashed $primary-05;
      opacity: 0.3;
      inset-inline-start: 5.1rem;
      inset-block-end: 4.4rem;
    }
  }

  .landing-page-wrapper {
    position: relative;
    min-height: calc(100vh - 3.4rem);
  }

  .main-landing-image {
    width: 37.5rem;
    padding: 0.5rem;
    border-radius: $default-radius;
    background-color: $primary-01;
  }

  .brand-img-white {
    display: block;
  }

  .brand-img-light {
    display: block;
  }

  .app-sidebar .side-menu__label {
    font-weight: 500;
    font-size: 0.9rem;
  }

  .landing-section-heading {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 60%;
      height: 0.25rem;
      background-image: linear-gradient(
        to right,
        $success,
        rgba(var(--success-rgb), 0.1)
      );
      opacity: 0.5;
      inset-block-start: -0.625rem;
      border-radius: 3.125rem;
      inset-inline-end: 0;
    }
  }

  .landing-missions,
  .landing-missions-dark {
    position: relative;
    overflow: hidden;
    z-index: 1;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05) !important;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.15rem;
      inset-block-start: 0;
      inset-inline-start: 0;
      border-radius: 3.125rem;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .customize-image img {
    width: 30.5rem;
    height: 20rem;
    margin-block-start: 20px;
    padding: 15px;
    position: relative;
  }

  .landing-footer {
    background-color: $landing-footer-color;
    border-block-end: 1px solid rgba(255, 255, 255, 0.05);

    .landing-footer-list {
      li {
        margin-block-end: 0.5rem;

        &:last-child {
          margin-block-end: 0;
        }
      }
    }
  }

  .landing-main-footer {
    background-color: $landing-footer-color;
  }

  .landing-testimonials .swiper-pagination-bullet {
    background: $primary !important;

    &:active {
      background: $primary !important;
    }
  }

  .team-card {
    transition: box-shadow 0.2s ease-in;

    &:hover {
      box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.05);
      transition: box-shadow 0.2s ease-in;
    }

    .team-avatar {
      box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0.05);
    }
  }

  .btn-close {
    inset-inline-end: -0.875rem;
  }

  .section-bg {
    background-color: $gray-1;
  }
}

[data-theme-mode="dark"] {
  .landing-body {
    @media (min-width: 992px) {
      .main-menu-container {
        .landing-logo-container {
          .horizontal-logo .header-logo {
            .desktop-logo {
              display: none !important;
            }
            .desktop-white {
              display: block;
            }
          }
        }
      }
      
      .landing-banner .main-banner-container {
        padding: 4rem 3rem;
      }
    }

    @media (max-width: 991.98px) {
      .app-header {
        .main-header-container {
          .horizontal-logo .header-logo {
            .toggle-logo {
              display: none;
            }

            .toggle-dark {
              display: block;
            }
          }
        }
      }
    }

    .landing-trusted-companies {
      img {
        filter: imvert(1);
      }
    }

    .team-card {
      &:hover {
        box-shadow: 0 0.25rem 1rem 0 rgba(255, 255, 255, 0.05);
      }

      .team-avatar {
        box-shadow: 0 0 0 0.5rem rgba(255, 255, 255, 0.05);
      }
    }
    .app-sidebar.sticky.sticky-pin {
      .landing-logo-container .horizontal-logo {
        .desktop-white {
          display: block;
        }
      }
    }
  }

  .brand-img-light {
    display: block;
  }

  .brand-img-white {
    display: none;
  }

  .section-bg {
    background-color: $black-1;
  }
}

@media (max-width: 420px) {
  .landing-body .landing-banner {

    .main-banner-container {
      padding: 1rem;
    }

    .landing-banner-heading {
      font-size: 2rem;
    }
  }
}
@media (max-width: 992px) {
  .landing-body {
    .app-sidebar .slide-menu.child1 li,
    .app-sidebar .slide-menu.child2 li,
    .app-sidebar .slide-menu.child3 li {
      padding: 0;
      -webkit-padding-start: 1.5rem;
      padding-inline-start: 0;
      position: relative;
    }
  }
}
[dir="rtl"] {
  .landing-body {
    .testimonial-card,.featured-card-1 {
      direction: rtl;
    }
  }
}
.landing-banner{
     .btn {
    position: absolute;
    inset-inline-end: 0rem;
    inset-block-start: 0.5rem;
    color: $text-muted;
    }
    h1 {
        font-weight: 500 !important;
        font-size: 3rem;
    }
    .sptb-tab {
        padding-block-start: 6.2rem;
        padding-block-end: 11rem;
    }
    .search-background {
        position: relative;
        .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
          border-color: transparent;
        }
    }
    .form-control {
        border: 1px solid $default-border;
        height: 55px !important;
        background: $form-control-bg !important;
    }
    .header-text p {
        margin-block-end: 1.5rem;
        font-size: 1.125rem;
    }
}
.landing-body{
    .suuport-body {
        padding: 3.5rem 1.5rem;
    }
    .choose-icon img {
        width: 100px;
        height: 100px;
        margin-block-end: 0.5rem;
    }
    .header-text3 {
        position: absolute;
        inset-block-end: -145px;
        inset-inline-end: 16rem;
        z-index: 2;
        width: 430px;
    }
    .widget-spec li {
        margin-block-end: 1.2rem;
        font-size: 0.85rem;
        border-block-end: 0;
    }
    .categ-icon i {
        font-size: 2.5rem;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            width: 35px;
            height: 35px;
            inset-block-start: 15px;
            inset-block-end: 0;
            border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
            inset-inline-end: -8px;
        }
        &.text-primary:after {
            background: rgba(51, 102, 255, 0.1);
            box-shadow: 0 5px 0.625rem rgba(51, 102, 255, 0.15);
        }
        &.text-orange:after {
            background: rgba(240, 74, 32, 0.1);
            box-shadow: 0 5px 0.625rem rgba(240, 74, 32, 0.15);
        }
        &.text-warning:after {
            background: rgba(255, 173, 0, 0.1);
            box-shadow: 0 5px 0.625rem rgba(255, 173, 0, 0.15);
        }
        &.text-danger:after {
            background: rgba(241, 21, 65, 0.1);
            box-shadow: 0 5px 0.625rem rgba(241, 21, 65, 0.15);
        }
        &.text-success:after {
            background: rgba(1, 195, 83, 0.1);
            box-shadow: 0 5px 0.625rem rgba(1, 195, 83, 0.15);
        }
        &.text-pink:after {
            background: rgba(251, 50, 146, 0.1);
            box-shadow: 0 5px 0.625rem rgba(251, 50, 146, 0.15);
        }
    }
    .list-article li {
        margin-block-end: 1rem;
        font-size: 0.85rem;
        border-block-end: 0px;
        position: relative;
        display: block;
        padding-inline-start: 2.3rem;
    }
    .list-article li i {
        position: absolute;
        inset-inline-start: 0;
        inset-block-start: -2px;
        width: 1.6rem;
        height: 1.6rem;
        line-height: 1.6rem !important;
        border-radius: 50px;
        display: inline-block;
        margin: 0 auto;
        text-align: center;
        background: $light;
        font-size: 18px;
    }
    .absolute-banner-section {
        position: relative;
        z-index: 9;
        inset-inline-start: 0;
        inset-inline-end: 0;
        inset-block-end: -100px;
    }
    .absolute-banner-section .card {
        box-shadow: 0 5px 40px 0 rgba(4, 4, 7, 0.1);
    }
    .sptb {
        padding-block-start: 5rem;
        padding-block-end: 5rem;
    }
    .landing-footer.footer-support {
      padding: 2rem;
      padding-block-start: 7.5rem;
  }
    .list-card {
      padding: 14px 25px;
      border-radius: 7px;
      margin-block-end: 12px;
      background: $custom-white;
      position: relative;
      border: 1px solid $default-border;
  }
    .captch-body img {
      padding: 5px;
      border-radius: 4px;
      background: #e9ebfa;
      border: 1px dashed #d3dfea;
  }
  .divider:after {
    content: "OR";
    display: block;
    position: relative;
    inset-block-start: -1.5em;
    font-size: 11px;
    padding: 0 0.25em;
    background: $light2;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: 9;
    inset-inline-start: 0;
    inset-inline-end: 0;
    line-height: 30px;
    margin: 0 auto;
    font-weight: 500;
    border: 1px solid $default-border;
    text-align: center;
  }
  .attach-supportfiles {
    width: 100%;
    float: left;
    background: $custom-white;
    border: 1px solid $default-border;
    position: relative;
    text-decoration: none !important;
    border-radius: 5px;
    text-align: center;
}
  .attach-supportfiles .img-options-remove {
    position: absolute;
    inset-inline-end: -0.625rem;
    inset-block-start: -8px;
    padding: 2px 4px;
    border: 1px solid $default-border;
    background: $light2;
    color: $default-text-color;
    font-size: 12px;
    border-radius: 50px;
    box-shadow: 0px 0.625rem 0.625rem 20px rgba(229, 230, 236, 0), 0px 0.625rem 15px -5px rgba(6, 10, 48, 0.2);
    opacity: 0;
  }
  .attach-supportfiles .attach-title {
    inset-block-end: -30px;
    background: $light2;
    line-height: 30px;
    padding: 0 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    width: 100%;
    font-weight: 500;
    font-size: 11px;
}
  .attach-supportfiles img {
    padding: 5px;
  }
  .attach-supportfiles:hover .img-options-remove {
      opacity: 1;
  }
  .create-ticket #note{
    height: 250px;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    .header-text3 {
        inset-inline-end: 4rem;
    }
  }

  .app-sidebar {
    .side-menu__item {
        &.active, &:hover {
            .side-menu__label, .side-menu__angle {
                color: $primary;
            }
        }
    }

    @media (max-width:991.98px) {
      .container-xl {
        padding-inline: 0;
      }
    }
  }
}
.fadeInUp {
  animation-name: fadeInUp;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0,10%,0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.support-card:hover {
  box-shadow: 0 6px 18px 4px #060a3014;
  transform: scale(1.02);
}
.support-card {
  transition-duration: .3s;
  cursor: pointer;
}
.widget-spec .categ-text:hover {
  transition: margin-inline-start 0.45s ease;
  margin-inline-start: 5px;
}
.widget-spec .categ-text {
  transition: margin-inline-start 0.45s ease;
}
.swiper.vertical.popular-articles-swiper {
  height: 24rem;
}

.pricing-offer .pricing-offer-details {
  position: absolute;
  width: 5.438rem;
  height: 3.75rem;
  background-color: $primary;
  color: $white;
  font-size: 0.75rem;
  transform: rotate(45deg);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  inset-inline-end: -2rem;
  inset-block-start: -1.25rem;
  padding: 0.313rem;
}

.mega-subtitle::before {
    position: absolute;
    inset-block-end: -13px;
    inset-inline-start: 0;
    content: "";
    width: 100%;
    border: 1px dashed $light;
}
[dir="rtl"] {
  .pricing-offer .pricing-offer-details {
    transform: rotate(-45deg);
  }
}
@media (min-width: 991.98px) {
  .landing-body .landing-banner .main-banner-container {
    padding: 4rem 3rem;
  }
}
/* End:: landing */