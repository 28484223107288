/* Start:: tags */
.tag {
	font-size: 0.75rem;
	color: $default-text-color;
	background-color: $default-background;
	border-radius: 3px;
	padding: 0 .5rem;
	line-height: 2em;
	display: -ms-inline-flexbox;
	display: inline-flex;
	cursor: default;
	font-weight: 400;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
a.tag {
	text-decoration: none;
	cursor: pointer;
	transition: .3s color, .3s background;
	&:hover {
		background-color: rgba(110, 118, 135, 0.2);
		color: inherit;
	}
}
/* End:: tags */