/* Sidemenu Layout Syles */
@media (max-width: 991.98px) {
    .sidemenu-layout-styles {
        display: none;
    }
}

/* Sidemenu Layout Syles */

/* Respoonsive Logo Styles */
@media (max-width: 991.98px) {
    .app-header .horizontal-logo .header-logo img {
        height: 2rem;
        line-height: 2rem;
    }
}

/* Respoonsive Logo Styles */

/* Dark Mode */

//dark mode styles start
[data-theme-mode="dark"] {

    @media (min-width: 992px) {
        &[data-nav-layout="vertical"]:not([data-menu-styles="light"]) {
            &[data-nav-style="icon-click"][data-toggled="icon-click-closed"],
            &[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"] {
                .app-sidebar .main-sidebar-header .header-logo .toggle-dark {
                    display: none;
                }
                .app-sidebar .main-sidebar-header .header-logo .toggle-white {
                    display: block;
                }
            }
        }
    }

    .app-sidebar .main-sidebar-header {
        .header-logo {
            .desktop-dark {
                display: block;
            }

            .desktop-logo,
            .toggle-logo,
            .toggle-dark,.desktop-white,.toggle-white {
                display: none;
            }
        }
    }

    .btn-close {
        filter: invert(1);
    }

    .alert {
        .btn-close {
            filter: unset;
        }
    }
    
    .app-header {
        box-shadow: none;
    }

    .dropdown-menu {
        box-shadow: 0 1rem 1.125rem rgba(40, 40, 40, 0.15);
    }

    .admisetting-tabs .nav-link.active {
        --default-background2: rgba(0, 0, 0, 0.75);
    }

    .hremp-tabs .tabs-menu1 ul li a {
        --default-background2: rgba(0, 0, 0, 0.4);
    }

    button.gridjs-sort,
    button.gridjs-sort-desc,
    button.gridjs-sort-asc {
        filter: invert(1);
    }

    .choices__list--dropdown,
    .choices__list[aria-expanded] {
        box-shadow: rgba(10, 10, 10, 0.2) 0 0.5rem 1.5rem !important;
    }

    .recent-activities .timeline>li {
      --default-border: rgba(0, 0, 0, 0.5);
    }

    ul.timeline>li {
        &::before {
            border-color: rgba(0, 0, 0, 0.5);
        }
        &.primary, &.pink, &.success {
            &::before {
                border-color: rgba(0, 0, 0, 0.5);
            }
        }

    }
}

//dark mode styles end

/* Dark Mode */

/* Responsive Menu Styles */

// responsive header logo styles start
[data-header-styles="dark"],
[data-header-styles="transparent"][data-theme-mode="dark"] {
    @media (max-width:991.98px) {
        .app-header .horizontal-logo .header-logo {
            .desktop-logo,
            .desktop-dark,
            .toggle-logo,.desktop-white,.toggle-white {
                display: none;
            }
            .toggle-dark {
                display: block;
            }
        }
    }
}
[data-header-styles="color"],
[data-header-styles="gradient"] {
    @media (max-width:991.98px) {
        .app-header .horizontal-logo .header-logo {
            .desktop-logo,
            .desktop-dark,
            .toggle-logo,.desktop-white,.toggle-dark {
                display: none;
            }
            .toggle-white {
                display: block;
            }
        }
    }
}

[data-header-styles="light"],
[data-header-styles="transparent"][data-theme-mode="light"] {
    @media (max-width:991.98px) {
        .app-header .horizontal-logo .header-logo {
            .toggle-logo {
                display: block;
            }
            .desktop-logo,
            .toggle-dark,
            .desktop-dark,.desktop-white,.toggle-white {
                display: none;
            }
        }
    }
}

// responsive header logo styles end

// toggle close start
[data-toggled="close"] {
    @media (max-width:991.98px) {
        .app-sidebar {
            transition: all 300ms ease;
            transform: translateX(-17rem);
        }

        .app-header {
            padding-inline-start: 0rem;

            .horizontal-logo {
                display: block;
            }
        }

        &[dir="rtl"] {
            .app-sidebar {
                transition: all 300ms ease;
                transform: translateX(17rem);
            }
        }
    }
}

// toggle close end

//toggle open start
[data-toggled="open"] {
    @media (max-width:991.98px) {
        .app-sidebar {
            transition: all 300ms ease;
            transform: translateX(0rem);
        }

        .app-header {
            padding-inline-start: 0rem;

            .horizontal-logo {
                display: block;
            }
        }
    }
}

//toggle open end

/* Responsive Menu Styles */

/* Page Styles */

// classic start
[data-page-style="classic"] {
    --default-border: #e1e9e8;
    --header-border-color: #e1e9e8;
    --menu-border-color: #e1e9e8;
    &[data-theme-mode="dark"] {
        --default-border: rgba(255,255,255,0.1);
        --header-border-color: rgba(255,255,255,0.1);
        --menu-border-color: rgba(255,255,255,0.1);
        &[data-menu-styles="light"] {
            --menu-border-color: #e9ebfa;
        }
    }
    body {
        background-color: $custom-white;
    }

    .card.custom-card {
        box-shadow: none;
        border: 1px solid $default-border;
    }

    .app-sidebar,
    .footer,
    .app-header,
    .app-sidebar .main-sidebar {
        box-shadow: none;
    }

    &[data-nav-layout="horizontal"] {
        .app-sidebar {
            border-block-end: 1px solid $default-border;
        }
        &[data-theme-mode="light"]:not([data-menu-styles="light"]) {
            .app-sidebar {
                border-block-end-color: rgba(255, 255, 255, 0.1);
            }
        }
        &[data-theme-mode="dark"][data-menu-styles="light"]{
            .app-sidebar {
                border-block-end-color: #e9ebfa;
            }
        }
    }
}
// page styles classic end

// page styles modern start
[data-page-style="modern"] {
    --light-rgb: 255,255,255;
    --custom-white: rgb(243, 246, 248);
    --default-border: #e6ebf1;
    .dropdown-menu {
        background-color: rgb(255,255,255);
    }
    body {
        background-color: #fff;
    }
    .card.custom-card {
        box-shadow: none;
        background-color: rgb(243, 246, 248);
    }
    &[data-theme-mode="dark"] {
        --light-rgb: 50, 52, 87;
        --custom-white: rgb(var(--body-bg-rgb));
        --default-border: rgb(255,255,255,0.1);
        .card.custom-card {
            box-shadow: none;
            background-color: var(--custom-white);
        }
        .dropdown-menu {
            background-color: $light;
        }
        body {
            background-color: var(--default-body-bg-color);
        }
        .btn-light {
            background-color: rgba(255,255,255,0.1) !important;
            border-color: rgba(255,255,255,0.1) !important;
            &:hover,&:focus,&:active {
                background-color: rgba(255,255,255,0.1) !important;
                border-color: rgba(255,255,255,0.1) !important;
            }
        }
    }
    .app-sidebar,
    .footer,
    .app-header,
    .app-sidebar .main-sidebar {
        box-shadow: none;
    }
    #switcher-canvas {
        .form-check-input {
            border-color: $black-1;
        }
    }
}
// page styles modern end

/* Page Styles */

/* Layout Width Styles */

// boxed start
[data-width="boxed"] {
    @media (min-width:1400px) {
        body {
            background-color: $default-background;
        }

        .page {
            width: 85%;
            margin: 0 auto;
            background-color: $default-body-color;
            position: relative;
            box-shadow: 0 0 1rem $black-1;

            .app-header {
                width: 85%;
                margin: 0 auto;
            }

            .app-sidebar {
                inset-inline-start: auto;
            }
        }
        &[data-nav-layout="vertical"][data-header-position="scrollable"] {
            .page .app-header {
                width: 100% !important;
            }
        }

        &[data-nav-layout="horizontal"] {
            .app-sidebar {
                width: 85% !important;
            }
        }
        &[data-nav-layout="horizontal"][data-header-position="scrollable"] {
            .app-sidebar {
                width: 100% !important;
            }
        }

        &[data-page-style="classic"] {
            .page {
                background-color: $custom-white;
            }

            .card {
                box-shadow: none;
            }
        }

        &[data-theme-mode="dark"] {
            --default-body-bg-color: rgba(var(--body-bg-rgb2), 1);

            body {
                background-color: rgba(var(--body-bg-rgb), 0.6);
            }
        }

        &[data-vertical-style="detached"] {
            .page {

                width: 1400px;
                box-shadow: none;
            }

            &[data-toggled=detached-close] {
                .app-content {
                    margin-inline-start: 5rem;
                }
            }

            .app-header .main-header-container {
                width: 1400px;
            }

            .app-content {
                margin-inline-start: 15rem;
            }
        }

        &[data-vertical-style=doublemenu] {
            .app-sidebar .main-sidebar {
                height: 100%;
            }

            .app-sidebar .simplebar-content-wrapper {
                position: initial;
            }

            .app-sidebar .simplebar-mask {
                position: inherit;
            }
        }
    }
}

// boxed end

/* Layout Width Styles */

/* Menu Positions Styles */

//scrollable start
[data-menu-position="scrollable"] {
    &[data-nav-layout="vertical"] {
        .app-sidebar {
            position: absolute;

            .main-sidebar-header {
                position: absolute;
            }
        }
    }

    &[data-nav-layout="horizontal"] {
        .app-sidebar {
            position: relative;
            z-index: 102;
        }

        .app-content {
            margin-block-start: 4rem;
        }

        &[data-header-position="scrollable"] {
            .app-sidebar {
                position: relative;
            }

            .app-header {
                position: relative;
            }
        }
    }
    @media (min-width: 1400px) {
        &[data-width="boxed"][data-nav-layout="horizontal"] .app-sidebar {
            width: 100%!important;
        }
    }

    @media(max-width:"991.98px") {
        &[data-nav-layout="horizontal"] {
            .app-sidebar {
                position: absolute;
            }

            .main-content {
                margin-block-start: 0;
            }

            .main-sidebar {
                height: 100%;
            }
        }
    }

    @media (min-width: 992px) {
        .app-content {
            min-height: calc(100vh - 2.5rem);
        }
    }
}

//scrollable end

/* Menu Positions Styles*/

/* Header Positions Styles */

//scrollable start
[data-header-position="scrollable"] {
    &[data-nav-layout="vertical"] {
        .app-header {
            position: absolute;
        }
    }

    &[data-nav-layout="horizontal"] {
        .app-sidebar {
            position: relative;
            inset-block-start: 0;

            &.sticky {
                position: sticky;
                inset-block-start: 0;
            }
        }

        .app-content {
            margin-block-start: 0;
        }

        .app-header {
            position: relative;
        }

        &[data-menu-position="scrollable"] {
            .app-sidebar {
                position: relative;
            }

            .app-header {
                position: relative;
            }
        }

        &[data-width="boxed"] {
            .app-header {
                margin: 0;
            }
        }
    }
    @media (min-width: 1400px) {
        &[data-width="boxed"][data-nav-layout="horizontal"] .page .app-header {
            width: 100% !important;
        }
    }

    @media (max-width:"991.98px") {
        &[data-nav-layout="horizontal"] {
            .app-sidebar {
                position: absolute !important;
                z-index: 102;
            }

            .main-content {
                padding-block-start: 0;
            }

            &[data-menu-position="fixed"] {
                .app-sidebar {
                    position: fixed !important;

                    .main-sidebar {
                        margin-block-start: 3.72rem;
                    }

                    &.sticky {
                        .main-sidebar {
                            margin-block-start: 0;
                        }
                    }
                }
            }
        }

        &[data-nav-layout="vertical"] {
            .app-sidebar.sticky {
                inset-block-start: 0;
            }
        }
    }
}

//scrollable end

/* Header Positions Styles*/

/* Menu Colors Styles*/

//menu-light start
[data-menu-styles="light"] {
    --menu-bg: #fff;
    --menu-prime-color: #536485;
    --menu-border-color: #f3f3f3;

    &[data-nav-layout="vertical"] {
        .app-sidebar .main-sidebar-header .header-logo {
            .desktop-logo {
                display: block;
            }

            .desktop-dark,
            .toggle-dark,
            .toggle-logo,.desktop-white,.toggle-white {
                display: none;
            }
        }
        .app-sidebar__user .user-pic {
            --light-rgb : 235, 239, 248;
            --custom-white: #fff;
            --text-muted: #9ba5ca;
        }
        .app-sidebar {
            .side-menu__item {
            &.active, &:hover {
                background-color: $primary;
            }
        }
            .slide-menu {
                &.child1,&.child2,&.child3 {
                    .side-menu__item {
                        &.active,
                        &:hover {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }

    .side-menu__label1 a {
        color: #222528;
        font-weight: 600;
    }

    .side-menu__label1 {
        border-block-end: 1px solid #e9ebfa !important;
    }
    &[data-nav-layout="horizontal"] {
        .app-sidebar {
            .side-menu__item {
                &.active,
                &:hover {
                    .side-menu__angle, .side-menu__label, .side-menu__icon {
                        color: $primary;
                    }
                }
            }
        }
    }

    @media (min-width:992px) {
        &[data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
            border-inline-end: 1px solid #f3f3f3;
        }
    }

    &[data-theme-mode="dark"] {
        .Annoucement_card {
            background: rgb(241, 244, 251);
            .text-white, .title {
                color: #263871!important;
            }
            .subtext {
                --text-muted:#9ba5ca;
            }
        }
    }
}

//menu-light end

//menu-dark start
[data-menu-styles="dark"] {
    --menu-bg: #{var(--custom-white)};
    --menu-border-color: rgba(255, 255, 255, 0.1);

    &[data-nav-layout="vertical"] {
        .app-sidebar .main-sidebar-header .header-logo {
            .desktop-dark {
                display: block;
            }

            .desktop-logo,
            .toggle-dark,
            .toggle-logo,.desktop-white,.toggle-white {
                display: none;
            }
        }
        .app-sidebar__user .user-pic img {
            border-color: rgba(255, 255, 255, 0.15);
        }
        .app-sidebar__user {
            border-color: rgba(255, 255, 255, 0.1);
        }

        &[data-vertical-style="overlay"][data-toggled="icon-overlay-close"] {
            .app-sidebar .main-sidebar-header .header-logo {
                .toggle-dark {
                    display: block;
                }

                .desktop-logo,
                .desktop-dark,
                .toggle-logo,.desktop-white,.toggle-white {
                    display: none;
                }
            }

            &[data-icon-overlay="open"] {
                .app-sidebar .main-sidebar-header .header-logo {
                    .desktop-dark {
                        display: block;
                    }

                    .desktop-logo,
                    .toggle-dark,
                    .toggle-logo,.desktop-white,.toggle-white {
                        display: none;
                    }
                }
            }
        }
    }

    .side-menu__label1 a {
        color: rgba(255, 255, 255);
        font-weight: 600;
    }

    .side-menu__label1 {
        border-block-end: 1px solid rgba(255, 255, 255, 0.1) !important;
    }

    .app-sidebar {
        .side-menu__item {
            &.active {
                background-color: $primary;
                color: #fff;
                .side-menu__icon,
                .side-menu__label,
                .side-menu__angle {
                    color: rgb(255, 255, 255);
                    fill: rgb(255, 255, 255);
                }
                &:hover {
                    background-color: $primary;
                }
            }
            &:hover {
                background-color: transparent;
            }
        }
        .slide-menu {
            &.child1,&.child2,&.child3 {
                .side-menu__item {
                    &.active,
                    &:hover {
                        .side-menu__angle {
                            color: $primary;
                        }
                        color: $primary;
                    }
                }
            }
        }
    }

    .app-sidebar .slide-menu.child1 .side-menu__item:hover,
    .app-sidebar .slide-menu.child2 .side-menu__item:hover,
    .app-sidebar .slide-menu.child3 .side-menu__item:hover {
        color: $primary;
        background-color: transparent;
    }

    .app-sidebar .slide-menu.child1 .side-menu__item:hover:before,
    .app-sidebar .slide-menu.child2 .side-menu__item:hover:before,
    .app-sidebar .slide-menu.child3 .side-menu__item:hover:before {
        border: 1px solid $primary;
        opacity: 1;
    }

    .app-sidebar .side-menu__item.active,
    .app-sidebar .side-menu__item:hover {
        color: #fff;
    }

    .app-sidebar .slide-menu.child1 .side-menu__item.active:before,
    .app-sidebar .slide-menu.child2 .side-menu__item.active:before,
    .app-sidebar .slide-menu.child3 .side-menu__item.active:before {
        border-color: $primary;
    }

    &[data-theme-mode="light"] {
        --menu-bg: #011626;

        &[data-bg-img="bgimg1"] {
            --menu-bg: url(../images/menu-bg-images/bg-img1.jpg);
        }

        &[data-bg-img="bgimg2"] {
            --menu-bg: url(../images/menu-bg-images/bg-img2.jpg);
        }

        &[data-bg-img="bgimg3"] {
            --menu-bg: url(../images/menu-bg-images/bg-img3.jpg);
        }

        &[data-bg-img="bgimg4"] {
            --menu-bg: url(../images/menu-bg-images/bg-img4.jpg);
        }

        &[data-bg-img="bgimg5"] {
            --menu-bg: url(../images/menu-bg-images/bg-img5.jpg);
        }
    }

    @media (min-width:992px) {

        &[data-nav-layout=horizontal] .main-menu-container .slide-left,
        &[data-nav-layout=horizontal] .main-menu-container .slide-right {
            background-color: rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(30px);
            border-color: rgba(255, 255, 255, 0.1);

            svg {
                fill: #fff;
            }
        }

        &[data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
            border-inline-end: 1px solid rgba(255, 255, 255, 0.1);
        }

        &[data-nav-layout=horizontal] {
            .app-sidebar {
                .side-menu__item {
                    &.active {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    &[data-theme-mode="light"] {
        --menu-bg: #17263a;
        --menu-prime-color: #aab3cc;
        .Annoucement_card {
            --light2: #1c2f48;
            --default-text-color: #ffffff;
        }
    }
}

//menu-dark end

//color and gradient menu color mixin start
@mixin color-gradient($color) {
    color: $color !important;

    &:before {
        border: 1px solid $color !important;
    }

    .side-menu__icon,
    .side-menu__label,
    .side-menu__angle {
        color: $color !important;
        fill: $color !important;
    }
}

//color and gradient menu color mixin end

//menu-color start
[data-menu-styles="color"] {
    --menu-bg: #{$primary};
    --menu-prime-color: rgba(255, 255, 255, 0.6);
    --menu-border-color: rgba(255, 255, 255, 0.1);

    &[data-nav-layout="vertical"] {
        .app-sidebar {
            .main-sidebar-header .header-logo {
                .desktop-white {
                    display: block;
                }

                .desktop-logo,
                .desktop-dark,
                .toggle-dark,
                .toggle-logo,.toggle-white {
                    display: none;
                }
            }
        }

        &[data-vertical-style="overlay"][data-toggled="icon-overlay-close"] {
            .app-sidebar .main-sidebar-header .header-logo {
                .toggle-white {
                    display: block;
                }

                .desktop-logo,
                .desktop-dark,
                .toggle-dark,
                .toggle-logo {
                    display: none;
                }
            }

            &[data-icon-overlay="open"] {
                .app-sidebar .main-sidebar-header .header-logo {
                    .desktop-white {
                        display: block;
                    }

                    .desktop-logo,
                    .desktop-dark,
                    .toggle-dark,
                    .toggle-white,
                    .toggle-logo {
                        display: none;
                    }
                }
            }
        }

        &[data-nav-style="icon-click"][data-toggled="icon-click-closed"],
        &[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"] {
            .app-sidebar .main-sidebar-header .header-logo {
                .toggle-white {
                    display: block;
                }

                .desktop-logo,
                .desktop-dark,
                .desktop-white,
                .toggle-logo {
                    display: none;
                }
            }
        }
    }

    .Annoucement_card {
        --light2: rgba(255,255,255,0.05);
        --default-text-color: #ffffff;
        --text-muted: #ffffff;
    }

    .app-sidebar__user {
        border-color: rgba(255, 255, 255, 0.1);
    }

    .side-menu__item {
        &.active {
            @include color-gradient($white)
        }

        &:hover {
            @include color-gradient($white)
        }
    }

    .slide-menu {
        --menu-bg: #{$primary};
    }

    .side-menu__label1 a {
        color: rgba(255, 255, 255);
        font-weight: 600;
    }

    .side-menu__label1 {
        border-block-end: 1px solid rgba(255, 255, 255, 0.1) !important;
    }

    .app-sidebar {
        .main-menu>.slide>.side-menu__item {

            &.active,
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }

        .main-menu .slide .side-menu__item:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    @media (min-width:992px) {

        &[data-nav-layout=horizontal] .main-menu-container .slide-left,
        &[data-nav-layout=horizontal] .main-menu-container .slide-right {
            background-color: rgba(255, 255, 255, 0.3);
            backdrop-filter: blur(30px);
            border: 0;

            svg {
                fill: $primary;
            }
        }

        &[data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
            border-inline-end: 1px solid rgba(255, 255, 255, 0.1);
        }
    }
}

//menu-color end

//menu-gradient start
[data-menu-styles="gradient"] {
    --menu-bg: linear-gradient(to top, #7739fc 0%, #{$primary} 100%);
    --menu-prime-color: rgba(255, 255, 255, 0.7);
    --menu-border-color: rgba(255, 255, 255, 0.1);

    &[data-nav-layout="horizontal"] {
        --menu-bg: linear-gradient(to right, var(--primary-color) 0%, #7739fc 100%);
    }

    .Annoucement_card {
        --light2: rgba(255,255,255,0.05);
        --default-text-color: #ffffff;
        --text-muted: #ffffff;
    }

    .app-sidebar .main-sidebar-header {
        background: $primary;
    }

    .app-sidebar__user {
        border-color: rgba(255, 255, 255, 0.1);
    }

    &[data-nav-layout="vertical"] {
        .app-sidebar .main-sidebar-header .header-logo {
            .desktop-white {
                display: block;
            }

            .desktop-logo,
            .desktop-dark,
            .toggle-dark,
            .toggle-white,
            .toggle-logo {
                display: none;
            }
        }

        &[data-vertical-style="overlay"][data-toggled="icon-overlay-close"] {
            .app-sidebar .main-sidebar-header .header-logo {
                .toggle-white {
                    display: block;
                }

                .desktop-logo,
                .desktop-dark,
                .desktop-white,
                .toggle-dark,
                .toggle-logo {
                    display: none;
                }
            }

            &[data-icon-overlay="open"] {
                .app-sidebar .main-sidebar-header .header-logo {
                    .desktop-white {
                        display: block;
                    }

                    .desktop-logo,
                    .desktop-dark,
                    .toggle-dark,
                    .toggle-white,
                    .toggle-logo {
                        display: none;
                    }
                }
            }
        }

        &[data-nav-style="icon-click"][data-toggled="icon-click-closed"],
        &[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"] {
            .app-sidebar .main-sidebar-header .header-logo {
                .toggle-white {
                    display: block;
                }

                .desktop-logo,
                .desktop-dark,
                .desktop-white,
                .toggle-logo {
                    display: none;
                }
            }
        }
    }

    .side-menu__item {
        &.active {
            @include color-gradient($white)
        }

        &:hover {
            @include color-gradient($white)
        }
    }

    .slide-menu {
        --menu-bg: #{$primary};
    }

    .side-menu__label1 a {
        color: rgba(255, 255, 255);
        font-weight: 600;
    }

    .side-menu__label1 {
        border-block-end: 1px solid rgba(255, 255, 255, 0.1) !important;
    }

    .app-sidebar {
        .side-menu__item {

            &.active,
            &:hover {
                background-color: rgba(255, 255, 255, 0.05);
            }
        }
    }

    @media (min-width:992px) {

        &[data-nav-layout=horizontal] .main-menu-container .slide-left,
        &[data-nav-layout=horizontal] .main-menu-container .slide-right {
            background-color: rgba(255, 255, 255, 0.3);
            backdrop-filter: blur(30px);
            border: 0;

            svg {
                fill: $primary;
            }
        }

        &[data-vertical-style=doublemenu] .slide.has-sub .slide-menu {
            border-inline-end: 1px solid rgba(255, 255, 255, 0.1);
        }
    }
}

//menu-gradient end

//menu-transparent start
[data-menu-styles="transparent"] {
    --menu-bg: #{$default-body-color};

    &[data-theme-mode="light"] {
        --menu-border-color: rgba(0, 0, 0, 0.07);
    }

    &[data-theme-mode="dark"] {
        --menu-prime-color: rgba(255, 255, 255, 0.6);
        --menu-border-color: rgba(255, 255, 255, 0.1);
        --menu-bg: rgb(var(--body-bg-rgb2));

        &[icon-overlay="open"] {
            --menu-bg: rgb(var(--body-bg-rgb2));
        }
    }

    &[data-nav-layout="vertical"] {
        &[data-theme-mode="dark"] {
            .app-sidebar .main-sidebar-header .header-logo {
                .desktop-dark {
                    display: block;
                }

                .desktop-logo,
                .toggle-dark,
                .toggle-logo,.desktop-white,.toggle-white {
                    display: none;
                }
            }

            &[data-vertical-style="overlay"][data-toggled="icon-overlay-close"] {
                .app-sidebar .main-sidebar-header .header-logo {
                    .toggle-dark {
                        display: block;
                    }

                    .desktop-logo,
                    .desktop-dark,
                    .toggle-logo,.desktop-white,.toggle-white {
                        display: none;
                    }
                }

                &[data-icon-overlay="open"] {
                    .app-sidebar .main-sidebar-header .header-logo {
                        .desktop-dark {
                            display: block;
                        }

                        .desktop-logo,
                        .toggle-dark,
                        .toggle-logo,.desktop-white,.toggle-white {
                            display: none;
                        }
                    }
                }
            }
        }

        &[data-theme-mode="light"] {
            .app-sidebar .main-sidebar-header .header-logo {
                .desktop-logo {
                    display: block;
                }

                .desktop-dark,
                .toggle-dark,
                .toggle-logo,.desktop-white,.toggle-white {
                    display: none;
                }
            }

            &[data-nav-style="icon-click"][data-toggled="icon-click-closed"],
            &[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"]
             {
                .app-sidebar .main-sidebar-header .header-logo {
                    .toggle-logo {
                        display: block;
                    }
                    .desktop-dark,
                    .toggle-dark,
                    .desktop-logo,.desktop-white,.toggle-white {
                        display: none;
                    }
                }
            }

            &[data-vertical-style="overlay"][data-toggled="icon-overlay-close"] {
                .app-sidebar .main-sidebar-header .header-logo {
                    .toggle-logo {
                        display: block;
                    }

                    .desktop-logo,
                    .desktop-dark,
                    .toggle-dark,.desktop-white,.toggle-white {
                        display: none;
                    }
                }

                &[data-icon-overlay="open"] {
                    .app-sidebar .main-sidebar-header .header-logo {
                        .desktop-logo {
                            display: block;
                        }

                        .desktop-dark,
                        .toggle-dark,
                        .toggle-logo,.desktop-white,.toggle-white {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &[data-nav-layout="vertical"] {
        .app-sidebar {
            .side-menu__item {

                &.active,
                &:hover {
                    background-color: $primary;
                }
            }
            .slide-menu {
                &.child1, &.child2, &.child3 {
                    .side-menu__item{
                        &.active {
                            color: $primary;
                        }
                        &:hover {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }

    &[data-theme-mode="dark"] {

        &[data-icon-overlay="open"] {
            .app-sidebar {
                backdrop-filter: blur(30px);
            }
        }
        @media (min-width: 991.98px) {
            &[data-nav-layout="horizontal"] {
                .app-sidebar.sticky.sticky-pin {
                    backdrop-filter: blur(30px);
                }

                &[data-nav-style=menu-click],
                &[data-nav-style=menu-hover],
                &[data-nav-style=icon-click],
                &[data-nav-style=icon-hover] {
                    .app-sidebar .slide.has-sub .slide-menu {
                        background-color: $custom-white;
                    }
                }
            }
        }

        &[data-nav-layout="vertical"] {

            &[data-nav-style=menu-click][data-toggled="menu-click-closed"],
            &[data-nav-style=menu-hover][data-toggled="menu-hover-closed"],
            &[data-nav-style=icon-click][data-toggled="icon-click-closed"],
            &[data-nav-style=icon-hover][data-toggled="icon-hover-closed"] {
                .app-sidebar .slide.has-sub .slide-menu {
                    background-color: $custom-white;
                }
            }
        }
    }

    @media (min-width:992px) {

        &[data-nav-layout="horizontal"] .main-menu-container .slide-left,
        &[data-nav-layout="horizontal"] .main-menu-container .slide-right {
            background-color: rgba(255, 255, 255, 0.3);
            backdrop-filter: blur(30px);
            border: 0;

            svg {
                fill: $primary;
            }
        }

        &[data-vertical-style="doublemenu"] {
            .side-menu__label1 {
                border-block-end: 1px solid rgba(0, 0, 0, 0.07);
            }

            .slide.has-sub .slide-menu {
                border-inline-end: 1px solid rgba(0, 0, 0, 0.07);
            }

            &[data-theme-mode="dark"] {
                .slide.has-sub .slide-menu {
                    border-inline-end: 1px solid rgba(255, 255, 255, 0.1);
                }

                .app-sidebar .main-sidebar-header {
                    border-inline-end: 0;
                    border-block-end: 0;
                }
            }
        }
    }
}

//menu-transparent end

/* Menu Colors Styles*/

/* Header Colors Styles*/

//header-light start
[data-header-styles="light"] {
    --header-bg: #fff;
    --header-border-color: #ebedfa;
    --header-prime-color: #536485;

    .main-header-container {
        --light2: rgb(241, 244, 251);
    }
    .app-header .form-inline {
        --default-text-color: #263871;
        --text-muted: #9ba5ca;
    }
}

//header-light start

//header-dark start
[data-header-styles="dark"] {
    --header-bg:#{var(--custom-white)};
    --header-border-color: rgba(255, 255, 255, 0.1);
    --header-prime-color: rgba(255, 255, 255, 0.6);

    &[data-nav-layout="horizontal"] {
        @media (min-width:992px) {
            .header-element .horizontal-logo .header-logo {
                .desktop-dark {
                    display: block;
                }

                .desktop-logo,
                .toggle-dark,
                .toggle-logo,.desktop-white,.toggle-white {
                    display: none;
                }
            }
        }
    }

    .main-header-container {

        .header-link:hover,
        .header-link.show {
            .header-link-icon {
                fill: $white;
            }
        }

        .header-link {
            background: rgba(255, 255, 255, 0.1);
        }
    }

    .app-header .form-inline {
        .form-control {
            background: rgba(255, 255, 255, 0.1);
            color: $white;
        }

        .btn i {
            color: $header-prime-color;
        }
    }

    &[data-theme-mode="light"] {
        --header-bg: #011626;
    }
}

//header-dark start

//header-color start
[data-header-styles="color"] {
    --header-bg:#{$primary};
    --header-border-color: rgba(255, 255, 255, 0.1);
    --header-prime-color: rgba(255, 255, 255, 0.6);

    &[data-nav-layout="horizontal"] {
        @media (min-width:992px) {
            .header-element .horizontal-logo .header-logo {
                .desktop-white {
                    display: block;
                }

                .desktop-logo,
                .desktop-dark,
                .toggle-dark,
                .toggle-white,
                .toggle-logo {
                    display: none;
                }
            }
        }
    }

    .main-header-container {

        .header-link:hover,
        .header-link.show {
            .header-link-icon {
                fill: $white;
            }
        }

        .header-link-icon:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }

        .header-link {
          background: rgba(255, 255, 255, 0.1);
        }
    }
    
    .header-search.form-control {
        &::-webkit-input-placeholder {
            color: rgba(255, 255, 255, 0.5);
        }
    }

    .app-header .form-inline {
        .form-control {
            background: rgba(255, 255, 255, 0.1);
            color: $white;
        }

        .btn i {
            color: $header-prime-color;
        }
    }
}

//header-color start

//header-gradient start
[data-header-styles="gradient"] {
    --header-bg:linear-gradient(to right, #{$primary} 0%, #7739fc 100%);
    --header-border-color: rgba(255, 255, 255, 0.1);
    --header-prime-color: rgba(255, 255, 255, 0.6);

    &[data-nav-layout="horizontal"] {
        @media (min-width:992px) {
            .header-element .horizontal-logo .header-logo {
                .desktop-white {
                    display: block;
                }

                .desktop-logo,
                .desktop-dark,
                .toggle-dark,
                .toggle-white,
                .toggle-logo {
                    display: none;
                }
            }
        }
    }

    .main-header-container {

        .header-link:hover,
        .header-link.show {
            .header-link-icon {
                fill: $white;
            }
        }

        .header-link-icon:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }

        .header-link {
          background: rgba(255, 255, 255, 0.1);
        }
    }
    
    .header-search.form-control {
        &::-webkit-input-placeholder {
            color: rgba(255, 255, 255, 0.5);
        }
    }

    .app-header .form-inline {
        .form-control {
            background: rgba(255, 255, 255, 0.1);
            color: $white;
        }

        .btn i {
            color: $header-prime-color;
        }
    }
}

//header-gradient start

//header-transparent start
[data-header-styles="transparent"] {
    --header-bg: rgb(var(--body-bg-rgb2));

    .main-header-container .header-link-icon:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }

    &[data-theme-mode="light"] {
        --header-border-color: rgba(0, 0, 0, 0.07);
        --header-bg: rgb(var(--body-bg-rgb));

        .main-header-container .header-link {
            background: rgba(0, 0, 0, 0.03);
        }

        .app-header .form-inline .form-control {
            background: rgba(0, 0, 0, 0.03);
        }
    }

    &[data-theme-mode="dark"] {
        --header-prime-color: rgba(255, 255, 255, 0.6);
        --header-border-color: rgba(255, 255, 255, 0.1);

        .main-header-container .header-link-icon:hover {
            background-color: rgba(255, 255, 255, 0.05);
        }
    }

    &[data-nav-layout="horizontal"] {
        @media (min-width:992px) {
            &[data-theme-mode="light"] {
                .header-element .horizontal-logo .header-logo {

                    .desktop-dark,
                    .toggle-logo,
                    .toggle-dark,.desktop-white,.toggle-white {
                        display: none;
                    }

                    .desktop-logo {
                        display: block;
                    }
                }
            }

            &[data-theme-mode="dark"] {
                .header-element .horizontal-logo .header-logo {
                    .desktop-dark {
                        display: block;
                    }

                    .desktop-logo,
                    .toggle-logo,
                    .toggle-dark,.desktop-white,.toggle-white {
                        display: none;
                    }
                }
            }
        }
    }
}

//header-gradient start

/* Header Colors Styles*/

/* Menu Background Image Styles */

.bg-img {
    @mixin slide-menu-bg($color) {
        &[data-nav-layout="horizontal"] {
            ul.slide-menu {
                background-size: cover !important;
                background-position: center !important;
                background-repeat: no-repeat !important;

                &:before {
                    content: "";
                    position: absolute;
                    inset-inline-start: 0;
                    inset-block-start: 0;
                    inset-inline-end: 0;
                    inset-block-end: 0;
                    border-radius: 0 0 0.25rem 0.25rem;
                    opacity: 0.9;
                    background: rgb(var(--body-bg-rgb));
                }
            }

            &[data-menu-styles="color"],
            &[data-menu-styles="dark"],
            &[data-menu-styles="gradient"] {
                ul.slide-menu:before {
                    display: none;
                }
            }

            &[data-theme-mode="dark"] {
                &[data-menu-styles="light"] {
                    ul.slide-menu:before {
                        background: rgb(245, 246, 247);
                    }
                }

                &[data-menu-styles="dark"] {
                    ul.slide-menu:before {
                        display: block;
                    }
                }
            }

            &[data-nav-layout="horizontal"][data-menu-styles="dark"][data-theme-mode="light"] {
                .app-sidebar .slide.has-sub .slide-menu {
                    background: #011626;
                }
            }
        }

        &[data-nav-layout="vertical"] {

            &[data-menu-styles="light"][data-nav-style=menu-click][data-toggled=menu-click-closed],
            &[data-menu-styles="light"][data-nav-style=menu-hover][data-toggled=menu-hover-closed] {
                .app-sidebar .main-sidebar-header {
                    background-color: rgb(255, 255, 255);
                }
                &[data-header-styles="light"] {
                    .app-sidebar .main-sidebar-header {
                        border-inline-end: 1px solid #e9ebfa;
                    }
                }
            }

            &[data-menu-styles="color"][data-nav-style=menu-click][data-toggled=menu-click-closed],
            &[data-menu-styles="color"][data-nav-style=menu-hover][data-toggled=menu-hover-closed] {
                .app-sidebar .main-sidebar-header {
                    background-color: $primary;
                }
            }

            &[data-menu-styles="gradient"][data-nav-style=menu-click][data-toggled=menu-click-closed],
            &[data-menu-styles="gradient"][data-nav-style=menu-hover][data-toggled=menu-hover-closed] {
                .app-sidebar .main-sidebar-header {
                    background: linear-gradient(to right, var(--primary-color) 0, #7739fc 100%);
                }
            }

            &[data-menu-styles="transparent"][data-nav-style=menu-click][data-toggled=menu-click-closed],
            &[data-menu-styles="transparent"][data-nav-style=menu-hover][data-toggled=menu-hover-closed] {
                .app-sidebar .main-sidebar-header {
                    background-color: rgb(var(--body-bg-rgb));
                }
            }
        }

        &[data-theme-mode="dark"] {
            &[data-menu-styles="transparent"] {
                .app-sidebar {
                    .slide.has-sub {
                        .slide-menu {

                            &.child1,
                            &.child2,
                            &.child3 {
                                background-color: transparent !important;
                            }
                        }
                    }
                }

                &[data-vertical-style="doublemenu"] {
                    .app-sidebar {
                        .slide.has-sub {
                            .slide-menu {
                                &.child1,
                                &.child2,
                                &.child3 {
                                    background-color: rgb(var(--body-bg-rgb2)) !important;
                                }
                            }
                        }
                        .side-menu__label1 {
                            border-block-end: 1px solid rgba(255, 255, 255, 0.1);
                        }
                    }
                    &[data-header-styles="light"], &[data-header-styles="dark"], &[data-header-styles="color"], &[data-header-styles="gradient"] {
                        .app-sidebar {
                            .slide.has-sub {
                                .slide-menu {
        
                                    &.child1,
                                    &.child2,
                                    &.child3 {
                                        background-color: rgb(var(--body-bg-rgb2)) !important;
                                    }
                                }
                            }
                        }
                        .side-menu__label1 {
                            border-block-end: 1px solid rgba(255, 255, 255, .1);
                        }
                    }
                    .app-sidebar .main-sidebar-header {
                        border-block-end: 1px solid rgba(255, 255, 255, 0.1);
                    }
                }

                &[data-nav-style="menu-click"][data-toggled="menu-click-closed"],
                &[data-nav-style="menu-hover"][data-toggled="menu-hover-closed"],
                &[data-nav-style="icon-hover"][data-toggled="icon-hover-closed"],
                &[data-nav-style="icon-click"][data-toggled="icon-click-closed"] {
                    .app-sidebar {
                        .slide.has-sub {
                            .slide-menu {

                                &.child1,
                                &.child2,
                                &.child3 {
                                    background-color: $custom-white !important;
                                }
                            }
                        }
                    }
                }
            } 
            &[data-menu-styles="light"] {
                .app-sidebar .main-sidebar-header {
                    border-block-end-color: rgba(0, 0, 0, 0.1);
                }
            } 
        }

        &[data-theme-mode="light"] {
            &[data-menu-styles="dark"] {
                .app-sidebar:before {
                    background-color: rgba(0,0,0,0.8);
                }
            }
        }
    }

    .app-sidebar:before {
        background-color: rgba(var(--body-bg-rgb), 0.8);
    }

    @include slide-menu-bg($white);

    &[data-vertical-style="doublemenu"] {
        .slide.has-sub .slide-menu {
            background-color: $white;
            background-image: none;
        }
    }

    &[data-nav-layout="vertical"][data-nav-style="menu-click"][data-toggled="menu-click-closed"],
    &[data-nav-layout="vertical"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"],
    &[data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"],
    &[data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"] {
        .app-sidebar {
            .slide.has-sub .slide-menu {
              background-color: $white;
              background-image: none;
            }
        }
        .app-sidebar {
        //   position: fixed;
          position: absolute;
        }
    }
    &[data-nav-layout="vertical"][data-nav-style="menu-click"],
    &[data-nav-layout="vertical"][data-nav-style="menu-hover"],
    &[data-nav-layout="vertical"][data-nav-style="icon-click"],
    &[data-nav-layout="vertical"][data-nav-style="icon-hover"] {
        .app-sidebar .main-sidebar-header {
            backdrop-filter: blur(30px);
        }
    }

    &[data-menu-styles="light"] {
        .app-sidebar:before {
            background-color: rgba(255, 255, 255, 0.85);
        }
        &[data-nav-layout="horizontal"] {
            .side-menu__label1 {
                border-block-end: 1px solid rgba(0, 0, 0, 0.1) !important;
            }
        }
    }

    &[data-menu-styles="dark"] {

        @include slide-menu-bg(rgba(0, 0, 0, 0.9));

        &[data-vertical-style="doublemenu"] {
            .slide.has-sub .slide-menu {
                background-color: var(--custom-white);
                background-image: none;
            }
        }
        &[data-theme-mode="light"] {
            &[data-vertical-style="doublemenu"] {
                .slide.has-sub .slide-menu {
                    background-color: #011626;
                }
            }
        }

        &[data-nav-layout="vertical"][data-nav-style="menu-click"][data-toggled="menu-click-closed"],
        &[data-nav-layout="vertical"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"],
        &[data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"],
        &[data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"] {
            .app-sidebar {
                .slide.has-sub .slide-menu {
                    background-color: #011626;
                    // background-color: rgb(var(--body-bg-rgb));
                    background-image: none;
                }
            }
        }

    }

    &[data-theme-mode="dark"] {
      &[data-vertical-style="doublemenu"] {
        .slide.has-sub .slide-menu {
          background-color: rgb(var(--body-bg-rgb));
        }
      }
      &[data-vertical-style="doublemenu"][data-menu-styles="light"] {
        .slide.has-sub .slide-menu {
          background-color: #fff;
        }
      }
  
      &[data-nav-layout="vertical"][data-nav-style="menu-click"][data-toggled="menu-click-closed"],
      &[data-nav-layout="vertical"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"],
      &[data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"],
      &[data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"] {
        .app-sidebar {
          .slide.has-sub .slide-menu {
            background-color: rgb(var(--body-bg-rgb));
            background-image: none;
          }
        }
      }
      &[data-menu-styles="light"] {
            &[data-nav-layout="vertical"][data-nav-style="menu-click"][data-toggled="menu-click-closed"],
            &[data-nav-layout="vertical"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"],
            &[data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"],
            &[data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"] {
                .app-sidebar {
                .slide.has-sub .slide-menu {
                    background-color: #fff;
                    background-image: none;
                }
                }
            }
        }
    }

    &[data-menu-styles="color"] {
        .app-sidebar:before {
            background-color: $primary;
            opacity: 0.9;
        }

        &[data-vertical-style="doublemenu"] {
            .slide.has-sub .slide-menu {
                background-color: $primary;
                background-image: none;
            }
        }

        &[data-nav-layout="vertical"][data-nav-style="menu-click"][data-toggled="menu-click-closed"],
        &[data-nav-layout="vertical"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"],
        &[data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"],
        &[data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"] {
          .app-sidebar {
            .slide.has-sub .slide-menu {
              background-color: $primary;
              background-image: none;
            }
          }
        }
    }

    &[data-menu-styles="gradient"] {
        .app-sidebar:before {
            background:linear-gradient(to right, $primary 0%, #7739fc 100%);
            opacity: 0.9;
        }

        &[data-vertical-style="doublemenu"] {
            .slide.has-sub .slide-menu {
                background-color: $primary;
                background-image: none;
            }
        }

        &[data-nav-layout="vertical"][data-nav-style="menu-click"][data-toggled="menu-click-closed"],
        &[data-nav-layout="vertical"][data-nav-style="menu-hover"][data-toggled="menu-hover-closed"],
        &[data-nav-layout="vertical"][data-nav-style="icon-click"][data-toggled="icon-click-closed"],
        &[data-nav-layout="vertical"][data-nav-style="icon-hover"][data-toggled="icon-hover-closed"] {
          .app-sidebar {
            .slide.has-sub .slide-menu {
              background-color: $primary;
              background-image: none;
            }
          }
        }
    }

    &[data-menu-styles="transparent"] {

        &[data-theme-mode="dark"] {
            --menu-prime-color: rgba(255, 255, 255, 0.6);
        }

        .app-sidebar:before {
            background-color: $default-body-color;
            opacity: 0.9;
        }

        @include slide-menu-bg($white);

        &[data-vertical-style="doublemenu"] {
            .slide.has-sub .slide-menu {
                background-color: transparent;
                background-image: none;
            }
            &[data-theme-mode="light"] {
                .slide.has-sub .slide-menu {
                    background-color: #f1f4fb;
                }
            }
            &[data-theme-mode="dark"] {
                .slide.has-sub .slide-menu {
                    background-color: rgb(var(--body-bg-rgb2));
                }
            }
        }

        &[data-nav-layout="vertical"][data-nav-style="menu-click"][data-toggled="menu-click-closed"] {
            .app-sidebar {
                .slide.has-sub .slide-menu {
                    background-color: $default-body-color;
                    background-image: none;
                }
            }
        }

        &[data-nav-layout="horizontal"] {
            .side-menu__label1 {
                border-block-end: 1px solid rgba(0, 0, 0, 0.1) !important;
            }
        }
    }

    .app-sidebar {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-inline-end: 0;

        .main-sidebar-header {
            background: transparent;
            border-inline-end: 0;
        }

        &:before {
            content: "";
            position: absolute;
            inset-inline-start: 0;
            inset-block-start: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }
    &[data-nav-layout="horizontal"] {
        .app-sidebar {
            border-block-end: 0;
        }
    }
}

//bg-img1 start
[data-bg-img="bgimg1"] {
    --menu-bg: url(../images/menu-bg-images/bg-img1.jpg);
    @extend .bg-img;

    &[data-menu-styles="transparent"][data-theme-mode="dark"] {
        --menu-bg: url(../images/menu-bg-images/bg-img1.jpg);
    }
}

//bg-img1 end

//bg-img2 start
[data-bg-img="bgimg2"] {
    --menu-bg: url(../images/menu-bg-images/bg-img2.jpg);
    @extend .bg-img;

    &[data-menu-styles="transparent"][data-theme-mode="dark"] {
        --menu-bg: url(../images/menu-bg-images/bg-img2.jpg);
    }
}

//bg-img2 end

//bg-img3 start
[data-bg-img="bgimg3"] {
    --menu-bg: url(../images/menu-bg-images/bg-img3.jpg);
    @extend .bg-img;

    &[data-menu-styles="transparent"][data-theme-mode="dark"] {
        --menu-bg: url(../images/menu-bg-images/bg-img3.jpg);
    }
}

//bg-img3 end

//bg-img4 start
[data-bg-img="bgimg4"] {
    --menu-bg: url(../images/menu-bg-images/bg-img4.jpg);
    @extend .bg-img;

    &[data-menu-styles="transparent"][data-theme-mode="dark"] {
        --menu-bg: url(../images/menu-bg-images/bg-img4.jpg);
    }
}

//bg-img4 end

//bg-img5 start
[data-bg-img="bgimg5"] {
    --menu-bg: url(../images/menu-bg-images/bg-img5.jpg);
    @extend .bg-img;

    &[data-menu-styles="transparent"][data-theme-mode="dark"] {
        --menu-bg: url(../images/menu-bg-images/bg-img5.jpg);
    }
}

//bg-img5 end

[data-vertical-style="icontext"] {
    &[data-bg-img="bgimg1"],
    &[data-bg-img="bgimg2"],
    &[data-bg-img="bgimg3"],
    &[data-bg-img="bgimg4"],
    &[data-bg-img="bgimg5"] {
      .app-sidebar {
        &.sticky.sticky-pin {
          .main-sidebar-header {
            backdrop-filter: blur(30px);
          }
        }
      }
    }
  }

/* Menu Background Image Styles */

/* horizontal Styles */
[data-nav-layout="horizontal"] {
    .custome-tooltip {
        display: none;
    }
}

/* horizontal Styles */

/* Loader Styles */
[loader="disable"] {
    #loader {
      display: none;
    }
}
/* Loader Styles */
