/* Start:: file-manager */
.file-manger-icon {
	position: relative;
	i {
	  font-size: 70px;
	  margin: 0 auto;
	  text-align: center;
	}
  
	img {
	  width: 70px;
	  height: 70px;
	}
  }
  .file-manger .icons {
    width: 30px;
    height: 30px !important;
    border-radius: 5px;
    background: transparent;
    text-align: center;
    line-height: 30px !important;
    display: block !important;
    padding: 0 !important;
    font-size: 16px;
    color: $gray-7 !important;
    border: 1px solid $default-border;
    margin-inline-end: 8px;
}
.file-manger.list-group-transparent .list-group-item {
    padding: 0.5rem;
    border: 0 !important;
}
.list-group-transparent .list-group-item {
    background: none;
    border: 0;
    padding: 0.75rem 1rem;
    border-radius: 3px;
}
/* End:: file-manager */