:root {
	--body-bg-rgb : 									241, 244, 251;
	--primary-rgb: 										51, 102, 255;
	--secondary-rgb :									254, 127, 0;
	--warning-rgb :										227, 177, 19;
	--info-rgb :										18, 138, 249;
	--success-rgb :										13, 205, 148; 
	--danger-rgb :										247, 40, 74;
	--light-rgb :										235, 239, 248;
	--dark-rgb :										53, 58, 64;
	--orange-rgb : 										243, 73, 50;
	--pink-rgb :                                       	239, 78, 184;
	--teal-rgb : 										23, 209, 220;
	--purple-rgb : 										170, 76, 242;
	--default-body-bg-color:							rgb(var(--body-bg-rgb));		
	--primary-color:	 								rgb(var(--primary-rgb));
	--primary-border: 									rgb(var(--primary-rgb));
	--primary01: 										rgba(var(--primary-rgb), 0.1);
	--primary02: 										rgba(var(--primary-rgb), 0.2);
	--primary03: 										rgba(var(--primary-rgb), 0.3);
	--primary04: 										rgba(var(--primary-rgb), 0.4);
	--primary05: 										rgba(var(--primary-rgb), 0.5);
	--primary06: 										rgba(var(--primary-rgb), 0.6);
	--primary07: 										rgba(var(--primary-rgb), 0.7);
	--primary08: 										rgba(var(--primary-rgb), 0.8);
	--primary09: 										rgba(var(--primary-rgb), 0.9);
	--primary005: 										rgba(var(--primary-rgb), 0.05);
	--default-font-family:    							'Roboto', sans-serif;
	--default-font-weight:								400;
	--default-text-color:       						#263871;
	--default-border:									#e9ebfa;
	--default-background: 								#f1f4fb;		
	--default-background2: 								#dee5f7;		
	--menu-bg:											#fff;	
	--menu-prime-color:									#536485;
	--menu-border-color:								#e9ebfa;
	--header-bg:                                        #fff;
	--header-prime-color:								#536485;
	--header-border-color:								#e9ebfa;
	--custom-white:										#fff;
	--custom-black:										#000;
	--bootstrap-card-border:							#e9ebfa;
	--list-hover-focus-bg:								#f5f6f7;
	--text-muted: 										#9ba5ca;
	--input-border: 									#e9edf6;
	--form-control-bg: 									#ffffff;
	--light2:											rgb(241, 244, 251);
	--light3:											rgb(222, 229, 247);
	--landing-footer-color:								#23293a;

	/* Gray set */
	--gray-1:											#f1f2f7;
	--gray-2:											#e5e7f1;
	--gray-3:											#d2d6e4;
	--gray-4:											#b7bdd2;
	--gray-5:											#a2aac7;
	--gray-6:											#8d96b7;
	--gray-7:											#7a85ad;
	--gray-8:											#53608c;
	--gray-9:											#424e79;

	/* White set */
	--white-05:											rgba(255,255,255, 0.05);
	--white-08:											rgba(255,255,255, 0.08);
	--white-75:											rgba(255,255,255, 0.075);
	--white-1:											rgba(255,255,255, 0.1);
	--white-2:											rgba(255,255,255, 0.2);
	--white-3:											rgba(255,255,255, 0.3);
	--white-4:											rgba(255,255,255, 0.4);
	--white-5:											rgba(255,255,255, 0.5);
	--white-6:											rgba(255,255,255, 0.6);
	--white-7:											rgba(255,255,255, 0.7);
	--white-8:											rgba(255,255,255, 0.8);
	--white-9:											rgba(255,255,255, 0.9);
	
	/* Black set */
	--black-1:											rgba(0,0,0, 0.1);
	--black-2:											rgba(0,0,0, 0.2);
	--black-3:											rgba(0,0,0, 0.3);
	--black-4:											rgba(0,0,0, 0.4);
	--black-5:											rgba(0,0,0, 0.5);
	--black-6:											rgba(0,0,0, 0.6);
	--black-7:											rgba(0,0,0, 0.7);
	--black-8:											rgba(0,0,0, 0.8);
	--black-9:											rgba(0,0,0, 0.9);
}


/*Font Family*/
$default-font-family:									var(--default-font-family);

/*Font Size*/
$default-font-size:										0.875rem;

/*Font Weight*/
$default-font-weight:									var(--default-font-weight);

/*Body & Text colors*/
$default-body-color: 									var(--default-body-bg-color);
$default-text-color:									var(--default-text-color);

/*Default Background Color*/
$default-background: 									var(--default-background);
$default-background2: 									var(--default-background2);

/*Border Color*/
$default-border:										var(--default-border);

/*Border Radius*/
$default-radius:										0.5rem;

/*Box Shadow*/
$box-shadow: 											0 0.125rem 0 rgba(10, 10, 10, .04);

/* Header Variables */
$header-bg:												var(--header-bg);
$header-prime-color:									var(--header-prime-color);
$header-border-color:									var(--header-border-color);

/*Menu Variables*/
$menu-prime-color: 										var(--menu-prime-color);
$menu-bg:												var(--menu-bg);
$menu-border-color:										var(--menu-border-color);

/*Color Variables*/
$primary:												var(--primary-color);
$primary-border:										var(--primary-border);
$primary-transparent:									var(--primary-transparent-color);
$primary-01:											var(--primary01);
$primary-02:											var(--primary02);
$primary-03:											var(--primary03);
$primary-04:											var(--primary04);
$primary-05:											var(--primary05);
$primary-06:											var(--primary06);
$primary-07:											var(--primary07);
$primary-08:											var(--primary08);
$primary-09:											var(--primary09);
$primary-005:											var(--primary005);
$custom-white:											var(--custom-white);
$custom-black:											var(--custom-black);
$secondary:												rgb(var(--secondary-rgb));
$warning:												rgb(var(--warning-rgb));
$info:													rgb(var(--info-rgb));
$success:												rgb(var(--success-rgb));
$danger:												rgb(var(--danger-rgb));
$light:													rgb(var(--light-rgb));
$light2:												var(--light2);
$light3:												var(--light3);
$dark:													rgb(var(--dark-rgb));
$orange:												rgb(var(--orange-rgb));
$pink:													rgb(var(--pink-rgb));
$purple:												rgb(var(--purple-rgb));
$teal:													rgb(var(--teal-rgb));
$red:                                                   #dc0441;
$blue:													#083dc1;
$green:													#1dd871;
$cyan:													#00D1D1;
$indigo:												#6574cd;
$white:													#fff;
$gray:													#8699A3;
$black:													#000;
$yellow:												#ecb403;
$text-muted:											var(--text-muted);
$bootstrap-card-border:									var(--bootstrap-card-border);
$list-hover-focus-bg:									var(--list-hover-focus-bg);
$input-border: 											var(--input-border);
$form-control-bg:										var(--form-control-bg);
$landing-footer-color:									var(--landing-footer-color);

/* social icons colors */
$facebook: 												#3b5998;
$twitter: 												#1da1f2;
$linkedin: 												#0077b5;
$github:												#333;
$google: 												#CF4E43;
$youtube: 												#FF0000;
$instagram: 											#de497b;

/*gradient variables*/
$primary-gradient:										linear-gradient(to bottom right, $primary 0%, #8e77fa 100%);
$secondary-gradient:									linear-gradient(to right, $secondary 0%, #d48435 100%);
$success-gradient:										linear-gradient(to right,$success 0%, #00A1C0 100%);
$warning-gradient:										linear-gradient(to right, $warning 0%, #7FA53A 100%);
$pink-gradient:											linear-gradient(to right, $pink 0%, #FFA795 100%);
$teal-gradient:											linear-gradient(to right, $teal 0%, #0695DD 100%);
$danger-gradient:										linear-gradient(to right,$danger 0%, #A34A88 100%);
$info-gradient:											linear-gradient(to right, $info 0%, #52F0CE 100%);
$orange-gradient:										linear-gradient(to right,$orange 0%,#9BA815 100%);
$purple-gradient:										linear-gradient(to right, $purple 0%, #FF496D 100%);
$light-gradient:										linear-gradient(to right, $light 0%, #D1D6DE 100%);
$dark-gradient:											linear-gradient(to right, $dark 0%, #54505D 100%);

/*gray set*/
$gray-1:												var(--gray-1);
$gray-2:												var(--gray-2);
$gray-3:												var(--gray-3);
$gray-4:												var(--gray-4);
$gray-5:												var(--gray-5);
$gray-6:												var(--gray-6);
$gray-7:												var(--gray-7);
$gray-8:												var(--gray-8);
$gray-9:												var(--gray-9);

/*white set*/
$white-05:												var(--white-05);
$white-08:												var(--white-08);
$white-75:												var(--white-75);
$white-1:												var(--white-1);
$white-2:												var(--white-2);
$white-3:												var(--white-3);
$white-4:												var(--white-4);
$white-5:												var(--white-5);
$white-6:												var(--white-6);
$white-7:												var(--white-7);
$white-8:												var(--white-8);
$white-9:												var(--white-9);

/*black set*/
$black-1:												var(--black-1);
$black-2:												var(--black-2);
$black-3:												var(--black-3);
$black-4:												var(--black-4);
$black-5:												var(--black-5);
$black-6:												var(--black-6);
$black-7:												var(--black-7);
$black-8:												var(--black-8);
$black-9:												var(--black-9);

/* dark mode */
[data-theme-mode="dark"] {
	--body-bg-rgb : 									37, 39, 74;
	--body-bg-rgb2 : 									21, 22, 54;
	--menu-bg:										  	rgb(var(--body-bg-rgb));
	--menu-border-color:								rgba(255,255,255,0.1);
	--menu-prime-color: 								rgba(233, 235, 250,0.9);
	--header-bg:										rgb(var(--body-bg-rgb));	
	--header-prime-color: 								rgba(255,255,255,0.6);
	--header-border-color:								rgba(255,255,255,0.1);
	--custom-white:										rgb(var(--body-bg-rgb));		
	--custom-black:										#fff;		
	--default-border:									rgba(255,255,255,0.1);	
	--default-text-color:       						rgb(233, 235, 250);
	--light-rgb:										56, 57, 90;	
	--light2:											rgba(255,255,255,0.05);	
	--light3:											rgb(var(--body-bg-rgb));	
	--dark-rgb :										240 ,245 ,248; 	
	--bootstrap-card-border:							rgba(255,255,255,0.1);		
	--list-hover-focus-bg:								rgba(255,255,255,0.1);
	--default-background:								rgba(255,255,255,0.07);
	--default-background2:								rgba(255,255,255,0.12);
	--default-body-bg-color:							rgb(var(--body-bg-rgb2));	
	--text-muted: 										rgba(255,255,255,0.5);
	--input-border: 									rgba(255, 255, 255, 0.07);
	--form-control-bg: 									#25274a;
	--landing-footer-color: 							#070715;

	/* Gray Set */
    --gray-1: 										#110f0f;
    --gray-2: 										#17171c;
    --gray-3: 										#393946;	
    --gray-4: 										#505062;
    --gray-5: 										#73738c;
    --gray-6: 										#8f8fa3;
    --gray-7: 										#ababba;
    --gray-8: 										#c7c7d1;
    --gray-9: 										#e3e3e8;

	/* white set */
	--white-05:											rgba(0,0,0,0.05);
	--white-08:											rgba(0,0,0,0.08);
	--white-75:											rgba(0,0,0,0.075);
	--white-1:											rgba(0,0,0,0.1);
	--white-2:											rgba(0,0,0,0.2);
	--white-3:											rgba(0,0,0,0.3);
	--white-4:											rgba(0,0,0,0.4);
	--white-5:											rgba(0,0,0,0.5);
	--white-6:											rgba(0,0,0,0.6);
	--white-7:											rgba(0,0,0,0.7);
	--white-8:											rgba(0,0,0,0.8);
	--white-9:											rgba(0,0,0,0.9);
	
	/* black set */
	--black-1:											rgba(255,255,255,0.05);
	--black-2:											rgba(255,255,255,0.2);
	--black-3:											rgba(255,255,255,0.3);
	--black-4:											rgba(255,255,255,0.4);
	--black-5:											rgba(255,255,255,0.5);
	--black-6:											rgba(255,255,255,0.6);
	--black-7:											rgba(255,255,255,0.7);
	--black-8:											rgba(255,255,255,0.8);
	--black-9:											rgba(255,255,255,0.9);
}