/* Start:: profile */
.profile-share {
    width: 100%;
    padding: 0.625rem;
    border-start-start-radius: 0;
    border-start-end-radius: 0;
    border-end-end-radius: 5px;
    border-end-start-radius: 5px;   
    border: 1px solid $default-border;
    display: flex;
}
.profile-edit textarea {
    border-start-start-radius: 5px;
    border-start-end-radius: 5px;
    border-end-end-radius: 0px;
    border-end-start-radius: 0px;
}
.personal-details {
    .table {
        td {
            border: 0;
        }
    }
}
.main-proifle {
	position: relative;
	background: $custom-white;
	padding: 25px;
	padding-block-end: 0;
	margin-block-end: 30px;
	border-radius: 8px;
	overflow: hidden;
	.tab-menu-heading {
		border-inline-start: 0;
		border-inline-end: 0;
		margin: 0 -30px;
		margin-block-start: 25px;
	}
	.avatar {
		border:3px solid $default-border;
		width: 7rem;
		height: 7rem;
		border-radius: 50%;
		.avatar-status{
			inset-inline-end: 17px;
    		inset-block-end: 3px;
		}
	}
	.icons{
		width: 1.6rem;
		height: 1.6rem;
		line-height: 1.6rem !important;
		border-radius: 50px;
		display: inline-block;
		text-align: center;
		background: $light;
		font-size: 18px;
	}
}
.profile-cover__action{
	background: url(../images/media/51.jpg) no-repeat;
    background-size: cover;
	background-position: center;
	height: 200px;
    position: relative;
    border-radius: 5px;
}
.user-pro-list2 {
	.user-pic {
		position: absolute;
		inset-block-end: -20px;
    	inset-inline-start: 20px;
		.avatar{
			border:3px solid $custom-white;
			width:6rem;
			height: 6rem;
			.avatar-status{
				inset-inline-end: 15px;
			}
		}
	}
	.user-pic-right{
		position: absolute;
		inset-block-end: 20px;
		inset-inline-end: 20px;
	}
}
.social-profile-buttons li {
	display: inline-block;
	a {
		display: block;
		width: 2rem;
		height: 2rem;
		line-height: 2rem;
		border-radius: 50%;
		background: $light;
		border: $default-border;
		margin: 2px;
		text-align: center;
	}
}

.avatar-status {
    position: absolute;
    inset-inline-end: -2px;
    inset-block-end: -2px;
    width: 0.75rem;
    height: 0.75rem;
    border: 2px solid $custom-white;
    background: $gray-5;
    border-radius: 50%;
}
.main-proifle .avatar .avatar-status {
    inset-inline-end: 17px;
    inset-block-end: 3px;
}
[dir="rtl"] {
	.fa-angle-right,
	.fe-chevron-right  {
		transform: scaleX(-1);
	}
}
@media (max-width: 768px) {
	.user-pro-list2 .card-footer .media {
		display: block;
	}
	.profile-cover__action .user-pic {
		inset-inline-start: 0 !important;
		inset-inline-end: 0 !important;
		margin: 0 auto !important;
		text-align: center !important;
	}
	.profile-cover__action .user-pic-right {
		inset-inline-end: 0 !important;
		inset-inline-start: 0 !important;
		margin: 0 auto !important;
		text-align: center !important;
		inset-block-end: -38% !important;
	}
	.pro-user.d-md-flex {
		padding-block-start: 5rem !important;
		text-align: center;
	}
}
/* End:: profile */