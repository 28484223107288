/* Start:: chat */

.chat {
	outline: 0;
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: end;
	justify-content: flex-end;
	min-height: 100%;
}
.chat-line {
	padding: 0;
	text-align: right;
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	+.chat-line {
		padding-block-start: 1rem;
	}
}
.chat-message {
	position: relative;
	display: inline-block;
	background-color: #467fcf;
	color: $white;
	font-size: 0.875rem;
	padding: .375rem .5rem;
	border-radius: 3px;
	white-space: normal;
	text-align: left;
	margin: 0 .5rem 0 2.5rem;
	line-height: 1.4;
	> :last-child {
		margin-block-end: 0 !important;
	}
	&:after {
		content: "";
		position: absolute;
		inset-inline-end: -5px;
		inset-block-start: 7px;
		border-block-end: 6px solid transparent;
		border-inline-start: 6px solid #467fcf;
		border-block-start: 6px solid transparent;
	}
	img {
		max-width: 100%;
	}
	p {
		margin-block-end: 1em;
	}
}
#messages-main {
	position: relative;
	border: $default-border;
	margin-block-end: 1.5rem;
	&:after, &:before {
		content: " ";
		display: table;
	}
	.ms-user {
		padding: 15px;
		background: $white;
		border: 1px solid #eceff9;
		border-inline-start: 0;
		>div {
			overflow: hidden;
			padding: 3px 5px 0 15px;
			font-size: 11px;
		}
	}
	#ms-compose {
		position: fixed;
		inset-block-end: 120px;
		z-index: 0;
		inset-inline-end: 30px;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
	}
}
@media (min-width: 992px) {
	#messages-main {
		.ms-menu {
			position: absolute;
			inset-inline-start: 0;
			inset-block-start: 0;
			border-inline-end:1px solid  $default-border;
			padding-block-end: 0;
			height: 100%;
			width: 300px;
		}
		.ms-body {
			padding-inline-start: 300px;
		}
	}
	.msb-reply {
		border-inline-start: 0 !important;
		border-inline-end: 0 !important;
	}
}
@media (max-width: 991.98px) {
	#messages-main {
		.ms-menu {
			z-index: 0;
			margin-block-end: 25px;
			&.toggled {
				display: block;
			}
		}
		.ms-body {
			overflow: hidden;
			.action-header {
				border-block-start: 1px solid $default-border;
			}
		}
	}
}
@media (max-width: 480px) {
	.ah-actions {
		float: none !important;
		text-align: center;
		display: inline-block;
	}
	.ah-actions {
		margin-inline-start: 50px !important;
	}
	.actions>li>a {
		padding: 0 5px !important;
	}
}
.login-content:after {
	content: "";
}
#footer, .fc-toolbar .ui-button, .fileinput .thumbnail {
	text-align: center;
}
.message-feed {
	padding: 20px;
	&.right>.pull-right {
		margin-inline-start: 15px;
	}
	&:not(.right) .mf-content {
		background: $light2;
		position: relative;
		margin-inline-start: 5px;
	}
	&.right .mf-content {
		background: $primary;
		color: $white;
		position: relative;
		margin-inline-end: 5px;
	}
}
.mf-content {
	padding: 13px 20px;
	border-radius: 6px;
	display: inline-block;
	max-width: 100%;
}
.mf-date {
	display: block;
	color: $text-muted;
	margin-block-start: 7px;
	>i {
		font-size: 11px;
		line-height: 100%;
		position: relative;
	}
}
.msb-reply {
	position: relative;
	background: $custom-white;
	border: 1px solid $default-border;
	height: 50px;
	border-block-end: 0;
	textarea {
		width: 100%;
		font-size: 13px;
		border: 0;
		padding: 15px 15px;
		resize: none;
		height: 50px;
		background: 0 0;
		color: $default-text-color;
	}
	button {
		position: absolute;
		inset-block-start: 9px;
		inset-inline-end: 9px;
		border: 0;
		height: 40px;
		width: 40px;
		font-size: 20px;
		color: $text-muted;
		background: 0 0;
	}
}
.msb-reply button:hover {
	background: transparent;
}
.p-15 {
	padding: 15px !important;
}
.actions {
	list-style: none;
	padding: 0;
	margin: 0;
	>li {
		display: inline-block;
	}
	&:not(.a-alt)>li>a>i {
		color: $white;
	}
	>li>a {
		>i {
			font-size: 20px;
		}
		display: block;
		padding: 0 0.625rem;
	}
}
.message-feed.right {
	text-align: right;
}
.list-group.lg-alt .list-group-item {
	border: 0;
}
.action-header {
	position: relative;
	background: $primary;
	padding: 15px 13px 15px 17px;
	border-block-end: 1px solid $default-border;
}
.ms-body {
	background: $custom-white;
	border-radius: 7px;
}
.rotation {
	-webkit-animation: rotation 2s infinite linear;
	animation: rotation 2s infinite linear;
}

@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}
@keyframes rotation {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}
.input-icon .form-group.is-empty {
	.form-control {
		margin-block-end: 0;
	}
	margin-block-end: 0;
}
/*=====================================
  Inbox Message Style
  =======================================*/
.chat-conatct-list .media:hover {
	z-index: inherit;
}
.list-icons i {
	font-size: 56px;
}
.font-dot {
	margin-block-start: -12px;
}
.list-icons .card-footer i {
	font-size: 14px;
}
.chat-body-style {
	background:$custom-white;
}
.chat-conatct-list {
	height: calc(100vh - 200px);
	overflow: auto;
}
.msg_card_body {
	background: url(../images/media/png/22.png);
	overflow-y: auto;
}
.chat-conatct-list .media {
	overflow: initial;
}
.message-feed {
	&.media .media-body, &.right .media-body {
		overflow: visible;
	}
	&:not(.right) .mf-content:before {
		content: "";
		position: absolute;
		inset-block-start: 9px;
		border-block-start: 8px solid transparent;
		border-block-end: 8px solid transparent;
		border-inline-start: 8px solid transparent;
		border-inline-end: 8px solid $default-border;
		inset-inline-end: auto;
		inset-inline-start: -15px;
		display: block;
	}
	&.right .mf-content:before {
		content: '';
		position: absolute;
		inset-block-start: 9px;
		border-block-start: 8px solid transparent;
		border-block-end: 8px solid transparent;
		border-inline-end: 8px solid transparent;
		border-inline-start: 8px solid $primary;
		inset-inline-start: auto;
		inset-inline-end: -16px;
		display: block;
	}
	&.right .media-body{
		display: flow-root;
	}
}
.ms-menu .tab-menu-heading {
	background: $custom-white;
	padding: 14.5px;
	border-radius: 5px 0 0 0;
	border-inline-end: 0;
	position: sticky;
	border-inline-start: 0;
	width: 299px;
	z-index: 0;
	.tabs-menu1 ul li {
		.active {
			border: 0;
			background: $primary;
			color: $white;
			border: 1px solid $primary;
		}
		width: 48%;
		margin: 0 1px;
		a {
			border-radius: 5px;
			padding: 0.625rem 20px;
			text-align: center;
			font-weight: 600;
			border: 1px solid $default-border;
		}
	}
}
.chat-conatct-list .list-group-item {
	position: relative;
	cursor: pointer;
	.chat-time {
		position: absolute;
		inset-block-start: 15px;
		inset-inline-end: 0.625rem;
		font-size: 12px;
	}
}
.chatbox {
	.user_info {
		margin-block-start: auto;
		margin-block-end: auto;
		margin-inline-start: 15px;
		span {
			color: #464444;
		}
	}
	.action-header small {
		font-size: 0.625rem;
	}
	.user_img {
		height: 50px;
		width: 50px;
		border: 0px solid $light;
	}
	.user_img_msg {
		height: 40px;
		width: 40px;
		border: 1.5px solid $light;
	}
	.img_cont {
		position: relative;
		height: 50px;
		width: 50px;
	}
	.img_cont_msg {
		height: 40px;
		width: 40px;
		display: contents;
	}
}
.chat-box-single-line {
	margin: 7px 0 30px;
	position: relative;
	text-align: center;
}
.chat abbr.timestamp {
	padding: 4px 14px;
	border-radius: 4px;
}
.chat {
	.action-header {
		position: relative;
		padding: 15px 13px 15px 17px;
	}
	.dropdown-menu.dropdown-menu-end {
		min-width: 12.75rem;
		&.show {
			width: 100%;
			margin-block-end: 5px;
			position: absolute;
			transform: translate3d(-176px, 22px, 0px);
			inset-block-start: 0.625rem !important;
			inset-inline-start: 0px;
			will-change: transform;
		}
	}
	.msb-reply textarea {
		width: 100%;
		font-size: 13px;
		padding: 12px 47px;
		resize: none;
		height: 44px;
		border: 1px solid #ebeaf1;
		background: #FAF9FF;
		margin-inline-start: -42px;
		color: $default-text-color;
	}
	.dropdown-menu li {
		i {
			padding: 7px;
		}
	}
	.actions {
		>li {
			display: inline-block;
		}
		&:not(.a-alt)>li>a>i {
			color: $white;
			line-height: unset;
		}
	}
	.justify-content-start, .justify-content-end {
		margin-block-end: 25px;
	}
	margin-block-end: auto;
	.card {
		height: 600px;
	}
	.card-header {
		padding: 1rem;
	}
	.video_cam {
		margin-inline-start: 50px;
		margin-block-start: 5px;
		span {
			color: white;
			font-size: 20px;
			cursor: pointer;
			margin-inline-end: 20px;
		}
	}
	.msg_cotainer {
		margin-block-start: auto;
		margin-block-end: auto;
		margin-inline-start: 0.625rem;
		background-color: $light;
		padding: 0.625rem;
		position: relative;
		border-radius: 5px;
		max-width: 70%;
		margin-block-start: 5px;
		&:before {
			content: '';
			position: absolute;
			inset-block-start: 9px;
			border-block-end: 8px solid $light;
			border-inline-start: 8px solid transparent;
			border-inline-end: 8px solid transparent;
			inset-inline-end: auto;
			inset-inline-start: -12px;
			transform: rotate(-90deg);
			display: block;
		}
	}
	.msg_cotainer_send {
		&:after {
			content: '';
			position: absolute;
			inset-block-start: 9px;
			border-block-end: 8px solid $primary;
			border-inline-start: 8px solid transparent;
			border-inline-end: 8px solid transparent;
			inset-inline-start: auto;
			inset-inline-end: -12px;
			transform: rotate(90deg);
			display: block;
		}
		margin-block-start: auto;
		margin-block-end: auto;
		margin-inline-end: 0.625rem;
		background-color: $primary;
		padding: 0.625rem;
		position: relative;
		color: $white;
		border-radius: 5px;
		max-width: 70%;
		margin: 0 12px 0.625rem 0;
	}
	.msg_time {
		position: absolute;
		inset-inline-start: 0;
		inset-block-end: -18px;
		font-size: 0.625rem;
	}
	.msg_time_send {
		position: absolute;
		inset-inline-end: 0;
		inset-block-end: -18px;
		color: $default-text-color;

		font-size: 0.625rem;
	}
	.msg_head {
		position: relative;
		background: #60529f;
		box-shadow: 0 1px 15px 1px rgba(58, 58, 62, 0.4);
	}
}
.construction .form-control {
	padding: 0.375rem 1rem;
}
.modal .ah-actions {
	margin-block-start: 0px !important;
}
.modal-content .actions>li>a {
	display: block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin: 0 auto;
	line-height: 50px;
	text-align: center;
	&:hover {
		background: $black-1;
	}
}
.msb-reply-button .btn {
	line-height: 14px;
}
.modal-content.chat .card {
	border-radius: 4px 4px 0 0;
	box-shadow: none;
}
.ah-actions {
	z-index: 1;
	margin-block-start: 0px !important;
	position: relative;
	&.actions a {
		line-height: inherit;
		height: auto;
	}
	.option-dots {
		width: 40px;
		height: 40px !important;
		border-radius: 5px;
		background: transparent;
		margin: 0 1px;
		text-align: center;
		line-height: 40px !important;
		display: block !important;
		padding: 0 !important;
		font-size: 18px;
		color: $gray-7;
	}
}
.ms-menu .dropdown-menu li a{
	font-size: 13px;
}
.ms-body .chat-body-style {
    height: calc(100vh - 200px);
	overflow: auto;
}
.ms-body {
	.action-header {
		background: $custom-white;
	}
}
.new-list2 {
	&.option-dots {
		height: 40px;
		width: 40px;
		background: 0 0;
		box-shadow: 0px 2px 3px rgba(4, 4, 7, 0.1);
		border: 1px solid $default-border;
		border-radius: 50px;
		font-size: 18px;
		line-height: initial;
		margin-inline-end: 8px;
		text-align: center;
		color: var(--gray-8);
	}
}
[data-theme-mode="dark"] {
	.msg_card_body {
		background: $custom-white;
	}
}

/* End:: chat */