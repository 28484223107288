/* Start:: timeline */
.timelinestart {
	position: relative;
	margin: 0 0 30px 0;
	padding: 0;
	list-style: none;
	&:before {
		content: '';
		position: absolute;
		inset-block-start: 0;
		inset-block-end: 0;
		width: 2px;
		background: $light3;
		inset-inline-start: 31px;
		margin: 0;
		border-radius: 2px;
	}
	>li {
		position: relative;
		margin-inline-end: 0;
		margin-block-end: 15px;
	}
}
.timelinestart>li:before {
	content: " ";
	display: table;
}
.timelinestart> {
	li:after {
		content: " ";
		display: table;
	}
	.timestart-label>span {
		font-weight: 600;
		padding: 5px 10px;
		display: inline-block;
		background-color: $white;
		color: $white;
		border-radius: 4px;
	}
	li {
		&:after {
			clear: both;
		}
		>.timelinestart-item {
			border-radius: 7px;
			margin-block-start: 0;
			background: $custom-white;
			margin-inline-start: 60px;
			margin-inline-end: 0;
			padding: 0;
			position: relative;
			border-radius: 13px;
			> {
				.time {
					color: $text-muted ;
					float: right;
					padding: 12px;
					font-size: 12px;
				}
				.timelinestart-header {
					margin: 0;
					border-block-end: $default-border;
					padding: .75rem 1.25rem;
					font-size: 16px;
					line-height: 1.1;
					&.no-border {
						border-block-end: 0px solid #e9ebfa;
					}
					>a {
						font-weight: 500;
						font-size: 14px;
					}
				}
			}
		}
	}
}
.timelinestart-body img {
	width: 150px;
	height: 100px;
}
.timelinestart>li>.timelinestart-item> {
	.timelinestart-body, .timelinestart-footer {
		padding: .75rem 1.25rem;
	}
}
.timelinestart-body {
	font-size: 14px;
}
.timelinestart>li> {
	.fa, .glyphicon, .ion {
		width: 30px;
		height: 30px;
		font-size: 12px;
		line-height: 30px;
		position: absolute;
		color: $white;
		background: #d2d6de;
		border-radius: 50%;
		text-align: center;
		inset-inline-start: 18px;
		inset-block-start: 0;
	}
}
.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
	&::before {
		display: block;
		content: "";
	}
	.embed-responsive-item, iframe, embed, object, video {
		position: absolute;
		inset-block-start: 0;
		inset-block-end: 0;
		inset-inline-start: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}
.embed-responsive-21by9::before {
	padding-block-start: 42.85714286%;
}
.embed-responsive-16by9::before {
	padding-block-start: 56.25%;
}
.embed-responsive-4by3::before {
	padding-block-start: 75%;
}
.embed-responsive-1by1::before {
	padding-block-start: 100%;
}
/* End:: timeline */