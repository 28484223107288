/* Start:: notifications */

/* notification list styles */
.notification {
    list-style-type: none;
    padding: 0;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        inset-block-start: 0.938rem;
        inset-block-end: 0.938rem;
        width: 0.188rem;
        background: $primary-02;
        inset-inline-start: 20%;
        margin-inline-start: -1px;
    }
    >li {
        position: relative;
        min-height: 3.125rem;
        padding: 0.938rem 0;
    }
    .notification-time {
        position: absolute;
        inset-inline-start: -0.438rem;
        width: 18%;
        text-align: end;
        inset-block-start: 1.875rem;
        .date {
            display: block;
            font-weight: 500;
            line-height: 1rem;
            font-size: 0.813rem;
            margin-block-end: 0.25rem;
            color: #8f8fb1;
        }
        .time {
            display: block;
            font-weight: 500;
            line-height: 1.5rem;
            font-size: 1.313rem;
            color: #8f8fb1;
        }
    }
    .notification-icon {
        inset-inline-start: 15%;
        position: absolute;
        width: 10%;
        text-align: center;
        inset-block-start: 2.563rem;
        a {
            text-decoration: none;
            width: 1.125rem;
            height: 1.125rem;
            display: inline-block;
            border-radius: 50%;
            background: #fff;
            line-height: 0.625rem;
            color: #fff;
            font-size: 0.875rem;
            border: 0.188rem solid $primary;
            transition: border-color 0.2s linear;
        }
    }
    .notification-body {
        box-shadow: 0px 0.5rem 1rem rgb(0 0 0 / 3%);
        margin-inline-start: 25%;
        margin-inline-end: 18%;
        background: $custom-white;
        position: relative;
        padding: 0.875rem 1.25rem;
        border-radius: 0.375rem;
        &::before {
            content: "";
            display: block;
            position: absolute;
            border: 0.625rem solid transparent;
            border-inline-end-color: $custom-white;
            inset-inline-start: -1.25rem;
            inset-block-start: 1.563rem;
        }
    }
    .notify-time {
        position: absolute;
        inset-block: auto;
        inset-inline-start: auto;
        inset-inline-end: 1.563rem;
    }
}

@media (max-width: 767px){
	
	.notification {
        &::before {
            display: none;
        }
        .notification-body {
            margin-inline: 0 !important;
        }
        .notification-time {
            .time,
            .date {
                display: none;
            }
        }
        .notification-icon {
            display: none;
        }
        .notify-time {
            display: none;
        }
    }
}
/* notification list styles */
/* End:: notifications */